<div class="input-button-container">
  <div class="input-container">
    <input
    type="text"
    placeholder="Search here..."
    [(ngModel)]="inputValue"
    class="form-control c-font-c-0 c-font-14">
    <strong><mat-icon class="clear-icon" (click)="clearInput()">X</mat-icon></strong>
  </div>
  
  <button class="btn-common mar-l-15" type="submit" (click)="onClick()">
    <i class="m-2 bi bi-search "></i>
  </button>
</div>

