<div class="container-fluid p-0 pad-h-10">
  <div class="row height-70 bg-white mb-3 fim-border-bottom pad-h-20">
    <div class="col-1 d-block d-md-none justify-content-center align-self-center">
      <i (click)="showSideBar()" class="bi bi-list-ul sideBarBtn cursor-pointer" style="font-size: 28px;"></i>
    </div>
    <div class="col-4 col-md-3 mar-t-10">
      <app-breadcrumbs></app-breadcrumbs>
    </div>
    <div class="col-4 col-md-5">
      <nav class="navbar navbar-expand-md bg-white nav-header header-component">
        <div [formGroup]="locationForm" class="w-100 justify-content-center align-self-center">
          <ul class="navbar-nav ms-auto right-top-menu text-right">
            <li *ngIf="fuelDay" class="nav-item d-none d-md-inline-block">
              <span class="application-name">
                <strong>Fuel Day :</strong>
                <div>
                  {{fuelDay | date: 'MM/dd/yyyy'}}
                </div>
              </span>
            </li>
            <li *ngIf="locations?.length > 0" id="location" class="nav-item" style="width: 60%;">
              <div class="form-group mt-1">
                <input placeholder="Select location" class="form-control" aria-label="State" [matAutocomplete]="auto"
                       formControlName="location"/>
                <mat-autocomplete [displayWith]="getOptionText || ''" #auto="matAutocomplete" (optionSelected)='reset()'>
                  <mat-option *ngFor="let location of filteredLocations" [value]="location">{{location.locationId}}
                    -{{location.description}}</mat-option>
                </mat-autocomplete>
                <div class="fim-error"
                     *ngIf="locationForm.get('location').touched && locationForm.get('location').hasError('notValid')">
                  select value from list
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="col-3 col-md-4 text-right">
        <div class="width-full rightCorner">
          <span class="_profileTitle d-inline-block ">
            {{userInfo?.name}}<br>
            {{userInfo?.userEmail}}
          </span>
          <span
            [routerLink]="'logout'"
            class="d-inline-block eis-status-secondary logoutBtn cursor-pointer">
            <i class="bi bi-box-arrow-right" style="font-size: 20px;"></i>
          </span>
        </div>
    </div>
  </div>
</div>