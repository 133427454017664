import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-ng-typeahead',
  templateUrl: './ng-typeahead.component.html',
  styleUrls: ['./ng-typeahead.component.scss']
})
export class NgTypeaheadComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('instance', {static: false}) instance: NgbTypeahead;
  @Input() customers = [];
  @Input() selected;
  @Input() isHiddenNumber = false;
  @Output() customerSelect: EventEmitter<any> = new EventEmitter();

  value: any;
  isDisabled = true;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  values = [];
  subscription = (new Subscription());

  constructor(private loginService: LoginService) {
  }

  ngOnInit(): void {
    this.loginService.fuelLocationAndDate$
    .subscribe(() => {
      this.value = '';
    })
  }

  ngOnChanges(): void {
    if (this.customers?.length > 0) {
      this.isDisabled = false;
      this.values = [];
      this.sortByCustomerName();
      this.customers.forEach((val: any) => {
        const title = this.isHiddenNumber ? `${val.customerName}` : `${val.customerId} - ${val.customerName}`;
        if (this.selected && +val.customerId === +this.selected?.customerId) {
          this.value = title;
        }
        this.values.push(title);
      });
    } else {
      this.isDisabled = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (
          term === ''
            ? this.values
            : this.values.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, this.values.length),
      ),
    );
  }

  onChange(event): void {
    const index = this.values.indexOf(event.item);
    this.customerSelect.emit(this.customers[index]);
  }

  reset(): void {
    this.value = null;
    this.customerSelect.emit(0);
  }

  sortByCustomerName(): void {
    function compare(a, b): number {
      if (a.customerName < b.customerName) {
        return -1;
      }
      if (a.customerName > b.customerName) {
        return 1;
      }
      return 0;
    }
    if (this.customers.length > 0) {
      this.customers.sort(compare);
    }
  }

}
