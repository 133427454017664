import {Component, EventEmitter, Input, Output, OnInit} from "@angular/core";
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {AppConstants} from "../../../app.constants";
import {Router} from '@angular/router';
import {LoginService} from "src/app/services/login.service";
import {takeUntil, filter, tap} from "rxjs";
import {LocationI} from "src/app/Modals/location-DateI";
import {Subject} from "rxjs";
import {CommonImportErrorsApiService} from "src/app/services/common-import-errors-api.service";
import { GroupsConstants } from "src/app/shared/authentication/groups.constants";
import { FuelClosingColorSerice } from "src/app/fuel-closing/fuel-closing-color-service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({height: AUTO_STYLE, visibility: AUTO_STYLE})),
      state('true', style({height: '0', visibility: 'hidden'})),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ]),
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('300ms ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 0}),
            animate('300ms ease-in',
              style({opacity: 0}))
          ]
        )
      ]
    )
  ]

})
export class SidebarComponent implements OnInit {

  @Output() handleSideBar = new EventEmitter<string>();
  minibar: boolean = false;
  loggedIn: boolean = false;
  collapseDisbursement: boolean = false;
  collapseReceiving: boolean = false;
  collapseReconciliation: boolean = true;
  collapseAdmin: boolean = true;
  collapseCorrectImportErrors: boolean = false;
  unsubscribeSubject = new Subject<boolean>();
  isNet: boolean = false;
  correctImportErrorsCount = 0;
  groupsConstants = GroupsConstants;
  showOptions = false;

  @Input()
  set setSideBar(value: boolean) {
    this.minibar = <boolean><unknown>value;
  }

  constructor(private router: Router,
    private fuelClosingColorSerice: FuelClosingColorSerice,
              private loginService: LoginService,
              private correctImportErrorsService: CommonImportErrorsApiService) {
  }

  ngOnInit(): void {

    this.correctImportErrorsService.correctImportsCount$
    .pipe(
      takeUntil(this.unsubscribeSubject),
      // filter(count => !!count)
    )
    .subscribe(
      (countResp) => this.correctImportErrorsCount = countResp
    );

    this.loginService.fuelLocationAndDate$
      .pipe(
        filter((location: LocationI) =>  !!location.locationId),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe((location: LocationI) => {
        this.showOptions = !!location.locationId;
        this.isNet = location.isNet
      });
  }

  showSideBar() {
    console.log('showSideBar initiated')
    setTimeout(() => {
      console.log('timer started')
      let userSettings = [{
        "key": "FIM",
        "settings": {"doNotShowAddPopUp": "false", "doNotShowSavePopUp": "false", "minimizeSidebar": "false"}
      }];
      // let userSettings = this.cacheService.getUserSettings();
      if (userSettings == null)
        this.showSideBar();
      else {
        this.minibar = false;
        // this.minibar = userSettings?.showSideBar == 'false';
        console.log('this.minibar', this.minibar);
        this.loggedIn = true;
      }
    }, 100);
  }

  toggleSidebar() {
    this.minibar = !this.minibar;
    this.handleSideBar.next(this.minibar.toString());
  }

  expand(key: string) {
    if (this.minibar == false) {
      if (key == 'importerrors') {
        this.collapseCorrectImportErrors = !this.collapseCorrectImportErrors;
        localStorage.setItem(AppConstants.LocalStorage_ManageMenuKey, this.collapseCorrectImportErrors.toString());
      } else if (key === 'disbursement') {
        this.collapseDisbursement = !this.collapseDisbursement;
        localStorage.setItem(AppConstants.LocalStorage_ManageMenuKey, this.collapseDisbursement.toString());
      } else if (key === 'reconciliation') {
        this.collapseReconciliation = !this.collapseReconciliation;
        localStorage.setItem(AppConstants.LocalStorage_ManageMenuKey, this.collapseReconciliation.toString());
      } else if (key === 'admin') {
        this.collapseAdmin = !this.collapseAdmin;
        localStorage.setItem(AppConstants.LocalStorage_ManageMenuKey, this.collapseAdmin.toString());
      } else {
        this.collapseReceiving = !this.collapseReceiving;
        localStorage.setItem(AppConstants.LocalStorage_ManageMenuKey, this.collapseReceiving.toString());
      }
    }
  }


  navigateTo(path: string) {
    this.router.navigate([path]);
  }

  onClosingOptionCLick() {
    this.fuelClosingColorSerice.setSelectedRecordInfo(null);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.complete();
  }
}
