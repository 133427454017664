import {Injectable} from "@angular/core";
import {HttpClientService} from "./http-client/http-client.service";
import {BehaviorSubject, Observable} from "rxjs";
import { ApiConstants } from "./api.constant";
import { LocationDateI } from "../Modals/location-DateI";
import { FuelDayRespI } from "../Modals/fuel-day-responseI";
import { UserInfoI } from "../Modals/user-infoI";

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private isValidLocation = new BehaviorSubject<boolean>(false);
  private isValidLocation$ = this.isValidLocation.asObservable();
  isAuthorized = new BehaviorSubject(false);
  isAuthorized$ = this.isAuthorized.asObservable();
  private userInfo = new BehaviorSubject<UserInfoI>({
    name: '', 
      username: '', 
      userEmail: ''
  });
  userInfo$ = this.userInfo.asObservable();
  private locationData = new BehaviorSubject({locationId: '', description: ''});
  public locationData$ = this.locationData.asObservable();

  private fuelLocationAndDate = new BehaviorSubject<LocationDateI>({
    locationId:   '',
    description:  '',
    companyId:    '',
    volumeUnit:   '',
    isNet:        false,
    settingValue: '',
    iata:         '',
    fuelDay:     '',
    locationMetric: ''
  });
  public fuelLocationAndDate$ = this.fuelLocationAndDate.asObservable();

  constructor(private httpClientService: HttpClientService) {
  }

  loginUser(userCredentials) {
    return this.httpClientService.post(ApiConstants.userAuthApiUrl, userCredentials);
  }

  getFuelDay(locationId): Observable<FuelDayRespI> {
    return this.httpClientService.get(`${ApiConstants.getFuelDayApiUrl}/${locationId}`);
  }

  getLocations(userId: string): Observable<any> {
    return this.httpClientService.get(`${ApiConstants.getLocationsApiUrl}/${userId}`);
  }

  isUserAuthorized(isLoggedIn: boolean) {
    this.isAuthorized.next(isLoggedIn);
  }

  storeUserData(userInfo: { name: string, username: string }) {
    let userObj = {
      name: userInfo.name,
      username: userInfo.username ? userInfo.username.split('@')[0] : '',
      userEmail: userInfo.username
    };
    this.userInfo.next(userObj);
  }

  setLocationData(locationData) {
    this.locationData.next(locationData);
  }

  setFuelLocationAndDate(locationAndDate: LocationDateI){
    if(locationAndDate.fuelDay && locationAndDate.locationId) 
    this.fuelLocationAndDate.next(locationAndDate);
  }

  setLocationValidity(valid: boolean) {
    this.isValidLocation.next(valid);
  }

  getLocationValidity$(): Observable<boolean> {
    return this.isValidLocation$;
  }

}
