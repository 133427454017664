export const ConformationMessageTypes = {

    error: {
        name: 'error_outline',
        backgroundColor: 'alert-primary',
        text: '#99999',
        iconColor: '',
        showClose: false,
        showBackgroundAndBox: true,
        className: 'alert-danger',
        iconName: 'bi-x-circle-fill'
    },

    warning: {
        name: 'error_outline',
        backgroundColor: 'alert-primary',
        text: '#99999',
        iconColor: '',
        showClose: false,
        showBackgroundAndBox: true,
        className: 'alert-warning',
        iconName: 'bi-exclamation-triangle-fill'
    },
    done: {
        name: 'error_outline',
        backgroundColor: 'alert-primary',
        text: '#99999',
        iconColor: '',
        showClose: false,
        showBackgroundAndBox: true,
        className: 'alert-success',
        iconName: 'bi-check-circle-fill'
    },
    info: {
        name: 'error_outline',
        backgroundColor: 'alert-primary',
        text: '#99999',
        iconColor: '',
        showClose: false,
        showBackgroundAndBox: true,
        className: 'alert-light'
    },
}