<nav class="breadcrumbs" [class.disabled-app]="!(isLocationSelected$ | async)">
  <a [routerLink]="'/'" class="breadcrumbs__item p-2">
    <i class="bi bi-house-door fs-2"></i> Home
  </a>
  <span
    [ngClass]="{'is-active': isLast}"
    *ngFor="let data of path; let isLast = last;"
    [routerLink]="data?.routerLink || null"
    class="breadcrumbs__item" style="padding-top: 10px;">
    {{data.title}}
  </span>
</nav>