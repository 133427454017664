<!-- <div class="col-12 col-md-6 col-lg-3">
    <div class="form-group"> -->
        <!-- <label for="fuelType" id="fuelTypeLabel">Test Type *</label> -->
        <mat-select 
            id="fuelTypeId" 
            name="fuelTypeId" 
            id="fuelTypeId"
            class="form-control c-font-c-0 c-font-14" 
            data-style="btn btn-primary btn-round"
            [formControl]="control" >
            <mat-option selected value="">{{placeholder}}</mat-option>
            <mat-option 
                *ngFor="let option of options" 
                [value]="!!propToBeBinded ? option[propToBeBinded] : option">
                <span *ngIf="optionsProps?.length > 0; else justString">
                    <ng-container *ngFor="let prop of optionsProps; let i = index">
                        <span>{{ option[prop] }}</span>
                        <ng-container *ngIf="i !== optionsProps.length - 1"> - </ng-container>
                    </ng-container>
                </span>
                <ng-template #justString>{{option}}</ng-template>                
            </mat-option>
        </mat-select>
    <!-- </div>
</div> -->

