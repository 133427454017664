import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';
import { AzureAdService } from '../shared/azure/azure-ad.service';

@Component({
  selector: 'app-has-no-role',
  templateUrl: './has-no-role.component.html',
  styleUrls: ['./has-no-role.component.scss']
})
export class HasNoRoleComponent implements OnInit {

  constructor(private spinner: SpinnerService,
              public azure: AzureAdService) {
  }

  ngOnInit(): void {
    this.spinner.hide();
  }

}
