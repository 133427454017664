import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AzureAdService } from '../shared/azure/azure-ad.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-login-old-ad',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private azure: AzureAdService,
              private router: Router,
              private spinner: SpinnerService) {
  }

  async ngOnInit(): Promise<void> {
    if (!this.azure.isAuth()) {
      this.spinner.show();
      await this.azure.login();
    } else {
      await this.azure.redirectOnSuccess(true);
    }
  }

}
