import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fim-select',
  templateUrl: './fim-select.component.html',
  styleUrls: ['./fim-select.component.css']
})

export class FimSelectComponent<T> {

  @Input() control: FormControl; /* form control */
  @Input() options: T[] = []; /* data to display in dropdown */
  @Input() optionsProps: string[]; /* based on these props, data will be displayed with in drop down list */
  @Input() propToBeBinded?: string; /* if requirement is to bind the whole obj to form-control then this is not required else pass prop name */
  @Input() placeholder?: string = 'Select...';
  @Input() label: string;

}
