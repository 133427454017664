import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


export interface DialogData {
  dialogTitle: string;
  dialogDescription: string;
  additionaltext: string;
  headerText: string;
  confirmHeader?: string;
  confirmButtonName: string;
  cancelButtonName: string;
  isCancel?: boolean;
}

@Component({
  selector: 'modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {
  isCancel: boolean = true;
  cancelButtonName: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<ModalPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.isCancel = typeof this.data.isCancel === 'undefined' || this.data.isCancel;
    this.cancelButtonName =  !!this.data?.cancelButtonName ;
  }

  onConfirm() {
    this.dialogRef.close(this.data);
  }

  onCancel() {
    this.dialogRef.close();
  }

}
