import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiConstants } from './api.constant';
import { AzureAdService } from '../shared/azure/azure-ad.service';
import { GroupsConstants } from '../shared/authentication/groups.constants';

@Injectable({
  providedIn: 'root'
})
export class CommonImportErrorsApiService {

  correctImportsCount = new BehaviorSubject(null);
  correctImportsCount$ = this.correctImportsCount.asObservable();
  correctImportsResponse = new BehaviorSubject(null);
  correctImportsResponse$ = this.correctImportsResponse.asObservable();

  constructor(private httpClient: HttpClient, private azureAdService: AzureAdService) {}

  getCommonImportErrors(locationId: string) {
    if (this.azureAdService.isRoleAllow(GroupsConstants.CORRECT_IMPORT_GROUPS)) {
      this.httpClient.get(`${ApiConstants.getCommonImportErrorsApiUrl}${locationId}`).subscribe(
        (response) => {
          this.setCount(response['content'].totalLocationErrors);
          this.setResponse(response)
        },
        () => {
          this.setCount(null);
          this.setResponse(null)
        }
      );
    } else {
      this.setCount(null);
      this.setResponse(null);
    }
  }

  getCommonImpoerErrorCount(locationId: string): void {
    if (this.azureAdService.isRoleAllow(GroupsConstants.CORRECT_IMPORT_GROUPS)) {
      this.httpClient.get(`${ApiConstants.getImportErrorsCountApiUrl}${locationId}`).subscribe(
        (response) => this.setCount(response['content'].totalLocationErrors),
        () => this.setCount(null)
      );
    } else {
      this.setCount(null)
    }
  }

  getdisbursementByErrorEventId(eventId: string, locationId: string) {
    return this.httpClient.get(`${ApiConstants.getdisbursementByErrorEventIdUrl}?eventId=${eventId}&locationId=${locationId}`);
  }

  setCount(count: number) {
    this.correctImportsCount.next(count);
  }

  setResponse(response) {
    this.correctImportsResponse.next(response);
  }

  deleteImportErrorById(eventId: string) {
    return this.httpClient.get(`${ApiConstants.deleteImportErrorByIdApiUrl}${eventId}`);
  }
}
