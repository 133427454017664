export class FuelClosingConstants {

    public static readonly notUniqueStatusSelectedWarningMessage = 'Please select either Closed or Open units to perform this action.';
    public static readonly unitClosedSuccessMessage = 'Success! Selected Unit(s) have been Closed.';
    public static readonly unitClosedFailMessage = 'Error! Failed to Close Selected Unit(s)';
    public static readonly unitReopenSuccessMessage = 'Success! Selected Unit(s) have been Re-opened.';
    public static readonly unitReopenFailMessage = 'Error! Failed to Re-open Selected Unit(s)';
    public static readonly fuelDayCloseSuccessMessage = 'Success! Current Fuel Day is Closed and New Fuel Day is updated.';
    public static readonly fuelDayCloseFailMessage = 'Error! Failed to close Current Fuel Day.';
    public static readonly deleteApiCallFailMessage = 'Error! Failed to delete Transaction.';
    public static readonly deleteApiCallSuccessMessage = 'Success! Trasaction deleted Successfully.';
    public static readonly unitStatusCheckApiCallFailMessage = 'Error! Unit status check API call failed.';
    public static readonly getAllClosingRecordsApiCallFailMessage = 'Error! Get All Closing Records API call failed.';
    public static readonly moveTransactionReopenSuccessMessage = 'Success! Selected Transaction have been moved to next fuel day.';
    public static readonly moveTransactionFailMessage = 'Error! Failed to move the selected transaction.';
    public static readonly Physical_Inventory = 'Physical Inventory';
    public static readonly Net_Conversion_Factor = 'Net Conversion Factor';
    public static readonly STOCK_TRANSFER = 'Stock Transfer';
    public static readonly RECEIPT = 'Receipt';
    public static readonly DISBURSEMENT = 'Disbursement';
    public static readonly colorsCodesEligibleForBulkSelect = ['Yellow', 'Green'];
    public static readonly checkIfTheseColorsExistsWhileClosingUnits = ['Black', 'Red1', 'Red2', 'Orange', 'Blue'];
    public static readonly allocationsDoneMessage = 'Please complete all Allocations to proceed.';
    public static readonly OK = 'OK';
    public static readonly minVarianceThreshold = -0.25;
    public static readonly maxVarianceThreshold = 0.25;

    public static readonly classList = [
        'mat-column-icon',
        'mat-column-expansion',
        'mat-column-checkbox',
        'mat-column-status',
        'mat-column-unit',
        'mat-column-asr',
        'mat-column-meter1Reading',
        'mat-column-meter2Reading',
        'mat-column-quantity',
        'mat-column-into-unit',
        'mat-column-fuel',
        'mat-column-owner-id',
        'mat-column-txn',
        'mat-column-customer',
        'bi-chevron-right',
        'mat-column-color-code'
    ];

    public static readonly disbursementTransaction = "Disbursement";
    public static readonly receiptTransaction = "Receipt";
    public static readonly DELETE_RECORD_API_FAILED = 'DELETE_RECORD_API_FAILED';
    public static readonly UNIT_STATUS_CHECK_API_FAILED = 'UNIT_STATUS_CHECK_API_FAILED';
    public static readonly FETCH_ALL_CLOSING_RECORDS_API_FAILED = 'UNIT_STATUS_CHECK_API_FAILED';


}

export namespace FuelClosingConstants {
    
    export enum ColorCodes {
        Orange = 'Orange',
         Black = 'Black',
         Red1 = 'Red1',
         Red2 = 'Red2',
         Yellow = 'Yellow',
         Green = 'Green',
         Blue = 'Blue',
         White = 'White'
    };

}