import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import { MessagesConstants } from '../../constants/validation-messages.constants';

@Component({
  selector: 'fim-typeahead',
  templateUrl: './fim-typeahead.component.html',
  styleUrls: ['./fim-typeahead.component.scss']
})

export class FimTypeaheadComponent<T> implements OnInit, OnDestroy  {

  value: any;
  modifiedOptions: T[] = [];
  messagesConstants = MessagesConstants;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  onFocusProp = false;
  unsubscribeSubject = new Subject<boolean>();
  private _control: FormControl;
  private _options: T[];
  private _label: string;
  private _optionsProps?: string[];
  private _placeholder: string = 'Type here';
  private _propToBeBinded?: string;
  controlId: string = '';

  @Input()
  set control(value: FormControl) {
    this._control = value;
  }

  get control(): FormControl {
    return this._control;
  }

  @Input()
  set options(value: T[]) {
    this._options = value;

    this.modifiedOptions = this.optionsProps && this.optionsProps.length === 1 ? value : value?.map((option) => ({ ...option, displayValue: this.buildString(option, this.optionsProps) }));
    if (this.control?.value && this.modifiedOptions?.length > 0) {
      this.value = this.propToBeBinded ? this.modifiedOptions.find(option => option[this.propToBeBinded] == this.control.value) : this.control.value;
    } else {
      this.value = '';
    }
  }

  get options(): T[] {
    return this._options;
  }

  @Input()
  set label(value: string) {
    this.controlId = this.buildControlIdString(value);
    this._label = value;
  }

  get label(): string {
    return this._label;
  }

  @Input()
  set optionsProps(value: string[]) {
    this._optionsProps = value;
  }

  get optionsProps(): string[] {
    return this._optionsProps;
  }

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  set propToBeBinded(value: string) {
    this._propToBeBinded = value;
  }

  get propToBeBinded(): string {
    return this._propToBeBinded;
  }

  @Input() touched: boolean = false;
  @Input() hasRequiredError: boolean = false;
  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  @Output() onChangeEmitter = new EventEmitter<any>();

  ngOnInit(): void {
    this.control.valueChanges
    .pipe(takeUntil(this.unsubscribeSubject))
    .subscribe((value) => {
      if(value === '') {
        this.value = value;
        this.control.markAllAsTouched();
        this.setValue(value, false);
      }

    });
  }


  buildString(option: T, props: string[]) {
    return props?.map((prop) => option[prop]).join(' - ');
  }

  reset() {
    this.value = '';
    this.setValue('');
    // this.setValue(this.propToBeBinded ? '' : {});
  }

  formatter = (result: T) => {
    return (this.concatenateValues(this.optionsProps, result));
  };

  private concatenateValues(props: string[], obj): string {
    return props.reduce((result, prop) => {
      if (obj.hasOwnProperty(prop)) {
        return result.concat(obj[prop]);
      }
      return result;
    }, []).join(' - ');
  }

  onChange(event): void {
    const index = this.modifiedOptions.indexOf(event.item);
    if (index > -1) {
      this.onFocusProp = true;
      const selectedObj = this.options[index];
      const finalValue = this.propToBeBinded ? selectedObj[this.propToBeBinded] : selectedObj;
      this.setValue(finalValue);
      this.onChangeEmitter.emit(finalValue);
    }
  }

  onInputBlur(): void {
    this.onFocusProp = true;
    this.control.markAllAsTouched();
    if (typeof this.value === 'string' && this.value.trim() !== '') {
      this.control.setErrors({ isValueNotSelected: true });
    } else if(typeof this.value === 'string' && this.value.trim() === '') {
        this.setValue('');
      }
  }

  private setValue(value: any, emitEvent: boolean = true) {
    this.control.markAllAsTouched();
    this.control.setValue(value, { emitEvent: emitEvent });
  }

  onFocus() {
    this.onFocusProp = false;
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
      (
        term === ''
          ? this.modifiedOptions
          : this.modifiedOptions.filter(
            (v) => this.optionsProps.length === 1 ?
              v[this.optionsProps[0]].toLowerCase().indexOf((term as string).toLowerCase()) > -1 :
              v['displayValue'].toLowerCase().indexOf((term as string).toLowerCase()) > -1
          )
      )
      ),
    );
  }

  buildControlIdString(label:string): string{
    return label.toLocaleLowerCase().replace(' *','').replace(' ','_');
  }


  ngOnDestroy(): void {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.complete();
  }

}
