import { NgModule } from '@angular/core'
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login.routing.module';
import { CommonModule } from '@angular/common';
import { AzureAdModule } from '../shared/azure/azure-ad.module';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    LoginRoutingModule,
    AzureAdModule,
    CommonModule
  ],
})

export class LoginModule {

}
