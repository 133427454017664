import { environment } from "src/environments/environment";

export class GroupsConstants {

    private static readonly ROLE_CORPORATEADMIN_USERSGROUPS = environment.azure.groups.corporateAdmin;
    private static readonly ROLE_LOCATIONADMIN_USERSGROUPS = environment.azure.groups.localAdmin;
    private static readonly ROLE_RECONCILIATION_USERSGROUPS = environment.azure.groups.reconciliation;
    private static readonly ROLE_DISBURSEMENTDATAENTRY_USERSGROUPS = environment.azure.groups.disbursementEntry;
    private static readonly ROLE_RECEIPTDATAENTRY_USERSGROUPS = environment.azure.groups.receiptEntry;

    public static readonly CORPORATEADMIN_USER = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS
    ];

    public static readonly LOCATIONADMIN_USER = [
        this.ROLE_LOCATIONADMIN_USERSGROUPS
    ];

    public static readonly RECONCILIATION_USER = [
        this.ROLE_RECONCILIATION_USERSGROUPS
    ];

    public static readonly DISBURSEMENTDATAENTRY_USER = [
        this.ROLE_DISBURSEMENTDATAENTRY_USERSGROUPS
    ];

    public static readonly RECEIPTDATAENTRY_USER = [
        this.ROLE_RECEIPTDATAENTRY_USERSGROUPS
    ];

    public static readonly RECEIPT_ENTRY_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS,
        this.ROLE_RECEIPTDATAENTRY_USERSGROUPS
    ];

    public static readonly DISBURSEMENT_ENTRY_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS,
        this.ROLE_DISBURSEMENTDATAENTRY_USERSGROUPS
    ];

    public static readonly CORRECT_IMPORT_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS
    ];

    public static readonly SEARCH_TRANSACTIONS_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS,
        this.ROLE_RECEIPTDATAENTRY_USERSGROUPS,
        this.ROLE_DISBURSEMENTDATAENTRY_USERSGROUPS
    ];

    public static readonly RECONCILIATION_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS
    ];

    public static readonly REOPEN_PREVIOUS_FUEL_DAY_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
    ];

    public static readonly ALLOCATIONS_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS
    ];

    public static readonly ADMIN_SCREEN_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
    ];

    public static readonly REPORT_SCREEN_GROUPS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS,
        this.ROLE_RECEIPTDATAENTRY_USERSGROUPS,
        this.ROLE_DISBURSEMENTDATAENTRY_USERSGROUPS
    ];

    public static readonly CORPORATE_LOCATIONADMIN_RECONCILIATION = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS,
        this.ROLE_RECONCILIATION_USERSGROUPS
    ];

    public static readonly SUPPLIER_FUEL_TYPE_USERS = [
        this.ROLE_CORPORATEADMIN_USERSGROUPS,
        this.ROLE_LOCATIONADMIN_USERSGROUPS
    ];
}
