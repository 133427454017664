import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {map, Observable} from 'rxjs';
import { LoginService } from './services/login.service';
import { LocationI } from './Modals/location-DateI';
import { UnitStatusApiService } from './services/unit-status-api.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { filter, switchMap } from 'rxjs';
import { ModalPopupComponent } from './shared/compponents/modal-popup/modal-popup.component';
import { AppConstants } from './app.constants';
import { AzureAdService } from './shared/azure/azure-ad.service';

@Injectable({
  providedIn: 'root'
})
export class AllocationsGuard  {


  constructor(private loginService: LoginService,
              private unitStatusApiService: UnitStatusApiService,
              private dialog: MatDialog,
              private azure: AzureAdService,
              private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | any {
    const roles: string[] = route.data.groups;
    if (!this.azure.isAuth() || (roles?.length && !this.azure.isRoleAllow(roles))) {
      this.router.navigateByUrl('/login');
    }
    return this.loginService.fuelLocationAndDate$
      .pipe(
        filter((locactionInfo: LocationI) => !!locactionInfo.locationId),
        switchMap((locactionInfo: LocationI) => this.unitStatusApiService.getUnitCloseStatus(locactionInfo.locationId).pipe(map((resp => {
          if (!resp.unitCloseStatus) {
            this.showModalWithExternalMessage();
          }
          // handle alert here
          return resp.unitCloseStatus;
        }
        ))))
      );
  }

  showModalWithExternalMessage() {

    let dialogConfg = new MatDialogConfig();
    dialogConfg = {
      data: this.buildModalOptions(AppConstants.unitsCloseMessage, 'Close', ''),
      disableClose: true
    };
    const dialogRef = this.dialog.open(ModalPopupComponent, dialogConfg);
    return dialogRef.afterClosed().pipe(
      filter((response) => !response),
    ).subscribe();
  }

  buildModalOptions = (dialogDescription: string, confirmButtonName: string, cancelButtonName?: string) => {
    return {
      dialogTitle: 'Warning',
      additionaltext: '',
      dialogDescription,
      headerText: '',
      confirmHeader: '',
      confirmButtonName,
      cancelButtonName,
      height: '400px',
      width: '600px'
    }
  }

}
