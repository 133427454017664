import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ConformationMessageTypes } from './conformation-message.constants';
import { ConfirmationMessage } from './modal';

@Component({
  selector: 'fim-conformation-message',
  animations: [
    trigger('inOutAnimation', [
      transition(
        ':leave', [
          style({opacity: 1}),
          animate('1s ease-in', style({opacity: 0}))
        ]
      )
    ])
  ],
  templateUrl: './conformation-message.component.html',
  styleUrls: ['./conformation-message.component.css']
})
export class ConformationMessageComponent implements OnInit {

  @Input() messages: string[] | string;
  @Input() messageType: string;
  @Input() showClose?: boolean;
  @Input() linkInfo?: string;
  @Input() hideSuccessMessage?: string;
  confirmationMessage: ConfirmationMessage;
  messageIsString: boolean = true;


  constructor() { }

  ngOnInit(): void {

    this.confirmationMessage = ConformationMessageTypes[this.messageType] || ConformationMessageTypes.error;
    this.confirmationMessage.showClose =  !!this.showClose;
    this.messageIsString = typeof this.messages === 'string';
  }
x
}
