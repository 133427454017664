import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})

export class GlobalSearchComponent  {

  inputValue: string = '';
  @Output() buttonClickEvent = new EventEmitter<any>();
  @Output() clearInputClickEvent = new EventEmitter<any>();



  onClick() {
    this.buttonClickEvent.emit(this.inputValue);
  }

  clearInput() {
    this.inputValue = '';
    this.clearInputClickEvent.emit();
  }

}
