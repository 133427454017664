import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { AppConstants } from '../../app.constants';

@Directive({
  selector: '[timeSecDatePicker]',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: AppConstants.TIME_SEC_DATE_FORMATS
    },
  ],
})
export class TimeSecDatePickerDirective {
}
