<div class="container-fluid min-vh-100 bg-light">
  <div class="row">
    <div *ngIf="showLayout"
      class="col-md-3 col-xl-2 position-fixed d-none d-md-block p-0 h-100"
      [ngClass]="{'show-side-bar col-6 col-sm-4': isSidebarVisible}" [class.disabled-app]="!(isLocationSelected$ | async)">
      <app-sidebar
        [setSideBar]="minibar">
      </app-sidebar>
    </div>
    <div [ngClass]="{'col-12 col-md-9 col-xl-10 offset-md-3 offset-xl-2': showLayout, 'col-12 p-0 m-0': !showLayout}">
      <div [ngClass]="{'col-12 sticky-top': showLayout}">
        <app-header
          *ngIf="showLayout"
          (handleSideBar)="handleSideBar($event)"
          [setBreadcrumb]="breadCrumb">
        </app-header>
      </div>
      <div class="body-background" [class.disabled-app]="!(isLocationSelected$ | async)">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(showSpinner$ | async)" class="overlay">
  <mat-progress-spinner class="spinner" [diameter]="100" [color]="'primary'" [mode]="'indeterminate'" [strokeWidth]="15" [value]="50"></mat-progress-spinner>
</div>

