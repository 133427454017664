import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class AuthService {

  getProfile() {

  }

  logout(redirect: boolean) {

  }

}
