<div class="alert alert-dismissible fade show" [ngClass]="confirmationMessage.className">
  <div *ngIf="messageIsString; else arrayType">
    <i class="bi mar-l-4" [ngClass]="confirmationMessage.iconName"></i>
    {{messages}}
  </div>
  <ng-template #arrayType>
    <ul *ngFor="let message of messages">
      <li>{{message}}</li>
    </ul>  
  </ng-template>
  <button *ngIf="confirmationMessage.showClose" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>

