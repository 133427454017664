import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { Observable } from 'rxjs';
import { ModalPopupComponent } from './shared/compponents/modal-popup/modal-popup.component';


export interface IDeactivateGuard {
  canExit: () => boolean | Promise<boolean> | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class FormChangesDeactivateGuardObject {
  formDirty = false;
}


@Injectable({
  providedIn: 'root'
})
export class UnsavedGuard  {

  constructor(private formChangesDeactivateGuardObject: FormChangesDeactivateGuardObject, private dialog: MatDialog) {

  }

  canDeactivate(): Observable<boolean> {

    return new Observable<boolean>((observer) => {
      if (this.formChangesDeactivateGuardObject.formDirty === false) {
        observer.next(true);
        observer.complete();
      } else {
        let dialogConfg = new MatDialogConfig();
        dialogConfg = {
          data: {
            dialogTitle: 'Warning',
            additionaltext: '',
            dialogDescription: 'You have unsaved changes. Are you sure you would like to exit this screen?',
            headerText: '',
            confirmButtonName: 'Return to Form',
            cancelButtonName: 'Exit',
            height: '400px',
            width: '400px'
          },
          disableClose: true
        };
        // dialogConfg.maxWidth = '500em'
        const dialogRef = this.dialog.open(ModalPopupComponent, dialogConfg);
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.formChangesDeactivateGuardObject.formDirty = false;
            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        })
      }
    })
  }

}
