import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'truncate'
})

export class TruncatePipe implements PipeTransform {
    transform(value: string | string[], charLength: number): string {
        if (!value || !charLength) {
            return '-';
        }
        const derivedValue = value && Array.isArray(value) && value.length > 0 ? value[0] : value.toString();
        return derivedValue.length > charLength ? derivedValue.substring(0, charLength-2).trim() + '...' : derivedValue;
    }

}
