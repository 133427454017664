export class ApiConstants {

    /* fuel receiving urls*/
    public static readonly fuelTypeApiUrl = 'fuelTypes';
    public static readonly supplierApiUrl = 'fuelSuppliers';
    public static readonly carrierApiUrl = 'fuelCarriers';
    public static readonly unitApiUrl = 'fuelunits';
    public static readonly ownerApiUrl = 'getOwnerIds';
    public static readonly getReceiptApiUrl = 'listofreceipts';
    public static readonly addReceiptApiUrl = 'addReceipt';
    public static readonly deleteReceiptApiUrl = 'DeleteReceiptById';
    public static readonly poNumbersApiUrl = 'getpoNumbers';
    public static readonly updateReceiptApiUrl = 'update';
    public static readonly receiptdetailsApiUrl = 'receiptdetails';
    public static readonly duplicateDocumentApiUrl = 'getreceiptbydocumentid'
    public static readonly getFuelDayApiUrl = 'getFuelDay';
    public static readonly getLocationsApiUrl = 'GetLocations';

    /* user auth urls*/
    public static readonly userAuthApiUrl = 'users/authenticate';

    /* Fuel disbursement urls*/
    public static readonly transactionTypesApiUrl = 'GetTransactionTypes';
    public static readonly aircraftTypesApiUrl = 'aircraftTypes';
    public static readonly customersApiUrl = 'Customers';
    public static readonly disbursementsListApiUrl = 'listofactivedisbursements';
    public static readonly specialTagsListApiUrl = 'getspecialtags';
    public static readonly addDisbursementApiUrl = 'adddisbursement';
    public static readonly ownerMappingsApiUrl = 'GetOwnerMappings';
    public static readonly deleteDisbursementApiUrl = 'disbursementById';
    public static readonly updateDisbursementApiurl = 'updatedisbursement';
    public static readonly getUnitToUnitDisbursementDetails = 'getunittounitdisbursementdetails';
    public static readonly addUnitToUnitDisbursement = 'addunittounitdisbursement';
    public static readonly updateUnitToUnitDisbursementApiurl = 'updateunittounitdisbursement'; 
    public static readonly addCorrectedFuelHubDisbursementApiUrl = 'addcorrectedfuelhubdisbursement'; 


    // updateunittounitdisbursement?userId=swartest

    /* Net converison factor urls*/
    public static readonly getConversionFactorsApiUrl = 'getNetConversionFactors';
    public static readonly saveNetConversionFactorsApiUrl = 'saveNetConversionFactors';

    /* Stock Transfers urls*/
    public static readonly saveStockTransferApiUrl = 'saveStockTransfer';
    public static readonly getStockTransferApiUrl = 'getstocktransferdetails';
    public static readonly updateStockTransferApiUrl = 'updateStockTransfer';

    /* Plysical Inventory urls */
    public static readonly getPhysicalInventory = 'getphysicalinventory';
    public static readonly savePhysicalInventoryApiUrl = 'addphysicalinventory';
    /**
     * fuel closing urls
     */
     public static readonly getAllUnitTransactionsApiUrl = 'getAllUnitTransactions';
     public static readonly getfueldaystatusApiUrl = 'getfueldaystatus' // Get Fuel Day Status
     public static readonly getunitstatusbylocationidApiUrl = 'getunitsclosestatus?locationId=' //Get Unit Status By Location Id
     public static readonly getUnitStatusByUnitIdAndFuelIdApiUrl = 'getunitstatusbyid' //Get Unit Status By Id
     public static readonly closefueldayApiUrl = 'closefuelday' //Close Fuel Day
     public static readonly closeUnitApiUrl =  'closeunit'
     public static readonly reopenUnitApiUrl =  'reopenunit';
     public static readonly deleteunittransactionApiUrl =  'deleteunittransaction';
     public static readonly deleteStockTransferApiUrl  = 'deletestocktransfer';
     public static readonly moveDisbursementToNextFuelDay = 'movedisbursementnextfuelday';
    /* search transaction urls*/
    public static readonly fuelSearchTicketApiUrl = 'fuelsearchticket';

    //common import errors
    public static readonly getCommonImportErrorsApiUrl = 'getImportErrors?locationId=';
    public static readonly getdisbursementByErrorEventIdUrl =  'getdisbursementByErrorEventId';
    public static readonly getImportErrorsCountApiUrl = 'getImportErrorsCount?locationId=';
    public static readonly deleteImportErrorByIdApiUrl = 'deleteImportErrorById?eventId=';

    // special tag
    public static readonly saveSpecialTagApiUrl = 'insertspecialtag';   
    public static readonly updateSpecialTagApiUrl = 'updatespecialtag'; 
    public static readonly getSpecialTagByIdApiUrl = 'getspecialtagdetails'; 
    public static readonly getSpecialTagsAllApiUrl = 'getspecialtagsall';

    // Owner Fuel Type
    public static readonly saveOwnerFuelTypeApiUrl = 'insertownerfueltype';   
    public static readonly updateOwnerFuelTypeApiUrl = 'updateownerfueltype'; 
    public static readonly getOwerFuelTypeDetailsApiUrl = 'getownerfueltypedetails';
    public static readonly getOwnerIdsApiUrl =  'getownerIds'; 
    public static readonly getAllOwners = 'getallowners';
    public static readonly getAllOwerFuelTypeDetailsApiUrl = 'getallownerfueltypedetails';


    // reopen fuel day
    public static readonly openPreviousFueldayApiUrl = 'openpreviousfuelday';
    public static readonly getpreviouscloseddayApiUrl = 'getpreviousclosedday?locationId=';

    // unit
    public static readonly saveUnitApiUrl = 'insertunit';   
    public static readonly updateUnitApiUrl = 'updateunit'; 
    public static readonly getUnitByIdApiUrl = 'getunitdetails'; 
    public static readonly getUnitsAllApiUrl = 'getunitsall'; 
    public static readonly getUnitTypesApiUrl = 'getunittypes'; 
    public static readonly getAgentsApiUrl = 'getAllLocationAgents'; 

    // supplier fuel type
    public static readonly saveSupplierFuelTypeApiUrl = 'insertsupplierfueltype';   
    public static readonly updateSupplierFuelTypeApiUrl = 'updatesupplierfueltype'; 
    public static readonly getSupplierFuelTypeDetailsApiUrl = 'getsupplierfueltypedetails'; 
    public static readonly getAllSupplierFuelTypes =  'getallsupplierfueltypes';
    public static readonly getSuppliersApiUrl = 'getsuppliers';

    // Customer Owner Mapping
    public static readonly saveCustomerOwnerMappingApiUrl = 'addcustomerownermapping';   
    public static readonly updateCustomerOwnerMappingApiUrl = 'updatecustomerownermapping'; 
    public static readonly getCustomerOwnerMappingByLocationApiUrl = 'getcustomerownermappingsall'; 
    public static readonly getCustomerOwnerMappingDetailsApiUrl = 'getcustomerownermappingbyid'; 

    // Customer Tail Number
    public static readonly saveCustomerTailNumberApiUrl = 'AddCustomerTailNumber';   
    public static readonly updateCustomerTailNumberApiUrl = 'UpdateCustomerTailNumber'; 
    public static readonly getCustomerTailNumberDetailsApiUrl = 'GetCustomerTailNumber'; 

    public static readonly getAllCustomerTailNumbersApiUrl = 'GetAllCustomerTailNumbers'; 

    // carrier location mapping
    public static readonly getAllCarriersApiUrl = 'getAllCarriers'; 
    public static readonly getAllLocationCarriersApiUrl = 'getAllLocationCarriers'; //getAllLocationCarriers?locationId=P73
    public static readonly saveLocationCarrierApiUrl = 'addLocationCarrier'; //saveLocationCarrier   
    public static readonly deleteLocationCarrierApiUrl = 'deleteLocationCarrier'; //deleteLocationCarrier?locationId=P73&carrierId=407
    
    // agent location mapping
    public static readonly getAllAgentsApiUrl = 'getAllAgents'; 
    public static readonly getAllLocationAgentsApiUrl = 'getAllLocationAgents'; //getAllLocationAgents?locationId=P73
    public static readonly getLocationAgentDetailsApiUrl = 'getLocationAgentDetails'; //getLocationAgentDetails?locationId=P73&agentId=64
    public static readonly saveLocationAgentApiUrl = 'saveLocationAgent'; //saveLocationAgent   
    public static readonly deleteLocationAgentApiUrl = 'deleteLocationAgent'; //deleteLocationAgent?locationId=P73&agentId=73
    public static readonly getAllAgentCategoriesApiUrl = 'getAllAgentCategories'; //getAllAgentCategories

    // customer location 
    public static readonly getallcustomersApiUrl = 'getallcustomers';
    public static readonly getallcategoryIdsApiUrl = 'getallcategoryIds';
    public static readonly getallcustomerlocationmappingApiUrl = 'getallcustomerlocationmapping?locationId=';
    public static readonly getcustomerlocationmappingdetailsApiUrl = 'getcustomerlocationmappingdetails?locationId=';
    public static readonly insertcustomerlocationmappingApiUrl = 'insertcustomerlocationmapping';
    public static readonly updatecustomerlocationmappingApiUrl = 'updatecustomerlocationmapping';
    public static readonly deleteCustomerLocationMappingApiUrl = 'deleteCustomerLocationMapping?';

    //review for billing
    public static readonly getReviewForBillingsApiUrl = 'getReviewForBillings';
    public static readonly sendClosedToFinancials = 'sendclosedtofinancials';

    //Fuel Transaction Detail Report
    public static readonly getClosedToFinancialStatus = 'getclosedtofinancialstatus';


    public static readonly isAllocationsDoneApiUrl = 'isAllocationsDone?locationId='
}
