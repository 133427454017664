import { Pipe, PipeTransform } from '@angular/core';
import { MaskDecimalService } from '../services/mask-decimal.service';
@Pipe({
  name: 'maskDecimalPipe'
})
export class MaskDecimalPipe implements PipeTransform {
  constructor(private service: MaskDecimalService) {
  }
  transform(orValue: number, isNegative: boolean = false): string {
    return this.service.getResult('' + orValue,isNegative);
  }
}
