import { Injectable } from '@angular/core';

@Injectable()

export class MaskDecimalService {
  getResult(value: string, isNegative: boolean = false): string {
    let result;
    const rounded = this.getRounded(value, isNegative);
    const decimal = this.getDecimal(value);
    if (value.split('.').length > 1) {
      const format = decimal.length > 1
        ? this.format(`${rounded}.${decimal}`, isNegative)
        : `${rounded}.${decimal}`;
      result = `${this.replaceThousands(this.getRounded(format, isNegative))}.${this.getDecimal(format)}`;
    } else {
      result = `${this.replaceThousands(this.getRounded(value, isNegative))}`;
    }
    return result;
  }

  format(value: string, isNegative: boolean = false): string {
    if(isNegative && (value.match(/[-]\d*.\d*/g) || value == '-'))
      return '-' + value.match(/\d+(?:\.\d{0,2})?/)[0]; ////     /^[-+]?\d*\.?\d{0,2}$/g
    else
      return value.match(/\d+(?:\.\d{0,2})?/)[0]; ////     /^[-+]?\d*\.?\d{0,2}$/g
  }

  getDecimal(value: string): string {
    return this.dropCharacters(value.split('.')[1] || '');
  }

  getRounded(value: string, isNegative: boolean = false): string {
    return this.dropCharacters(value.split('.')[0] || '',isNegative);
  }

  dropCharacters(value: string, isNegative: boolean = false): string {
    if(isNegative && (value.match(/[-]\d*.\d*/g) || value == '-'))
      return '-' + value.replace(/\D+/g, '');
    else
    return value.replace(/\D+/g, '');
  }

  replaceThousands(value: string): string {
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}
