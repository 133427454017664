import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UnitsStatusResponseI } from '../Modals/units-status-api-responseI';
import { ApiConstants } from './api.constant';
import { UnitStatusByIdPayloadI } from './unit-statusI';
import { IsAllocationsDoneAPIRespI } from '../Modals/isAllocationsDoneApiResponseI';

@Injectable({
  providedIn: 'root'
})
export class UnitStatusApiService {

  constructor(private http: HttpClient) { }

  getUnitStatus(locationId: string): Observable<{unitsStatus: boolean}> {
    return this.http.get(`${ApiConstants.getunitstatusbylocationidApiUrl}${locationId}`).pipe(
      map((response: UnitsStatusResponseI) => ({ unitsStatus: this.sendStatus(response) }))
    );
  }
  getFuelDayStatus(locationId: string, fuelDay: string): Observable<{fuelDayStatus: boolean}> {
    return this.http.post(ApiConstants.getfueldaystatusApiUrl, {locationId,fuelDay}).pipe(
      map((response) => ({fuelDayStatus: response['content'].fuelDayStatus === 'Open'}))
    );
  }
  sendStatus(response): boolean {
    return response.content.unitsCloseStatus === 'Open';
    // return false;
  }

  getUnitCloseStatus(locationId: string): Observable<{unitCloseStatus: boolean}> {
    return this.http.get(`${ApiConstants.getunitstatusbylocationidApiUrl}${locationId}`).pipe(
    map((response: UnitsStatusResponseI) => ({ unitCloseStatus: this.isClosed(response) }))
    );
  }

  isClosed(response): boolean {
    return response.content.unitsCloseStatus === 'Closed';
  }

  getUnitStatusByUnitIdAndFuelId(payload: UnitStatusByIdPayloadI): Observable<{unitStatus: boolean}> {
    return this.http.post(ApiConstants.getUnitStatusByUnitIdAndFuelIdApiUrl, payload).pipe(
      map((response: UnitsStatusResponseI) => ({ unitStatus: this.sendStatusById(response) }))
    );
  }

  sendStatusById(response): boolean {
    return response.content.unitCloseStatus === 'Open';
    // return false;
  }

  isAllocationDoneStatus(locationId: string): Observable<boolean> {
    return this.http
      .get<IsAllocationsDoneAPIRespI>(`${ApiConstants.isAllocationsDoneApiUrl}${locationId}`)
      .pipe(
        map((resp) => resp.content.allocationsDone)
      );
  }


}
