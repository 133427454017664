import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {LoginService} from "./services/login.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {distinctUntilChanged, filter, map, mergeMap} from "rxjs";
import { LoaderService } from './services/loader-service';
import { AzureAdService } from './shared/azure/azure-ad.service';
import { StorageService } from './services/user/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'fim-ui';
  minibar: boolean = false;
  showLayout: boolean = true;
  breadCrumb!: string;
  isAuth = false;
  subscription = new Subscription();
  showSpinner$: Observable<boolean>;
  isSidebarVisible = false;
  isLocationSelected$ = this.loginService.getLocationValidity$().pipe(distinctUntilChanged());

  constructor(private loginService: LoginService,
              private router: Router,
              private storage: StorageService,
              private loaderService: LoaderService,
              private activatedRoute: ActivatedRoute,
              private azure: AzureAdService) {
  }


  ngOnInit() {
    this.showSpinner$ = this.loaderService.loading$.pipe(
      mergeMap((mainSpinnerResp) => this.loaderService.disableSpinnerIndicator$.pipe(
        map(spinnerNotRequiredResp =>   mainSpinnerResp && spinnerNotRequiredResp)
      ))
    )
    this.isUserAuthorized();
    this.defineRoute();
    this.azure.onInit();
    this.isAuth = this.azure.isAuth();
    console.log('Pipeline-test');
  }

  @HostListener('document:click', ['$event'])
  onClickEvent(event): void {
    if (event?.target?.className?.search && event.target.className.search('sideBarBtn') < 0 && event.target.className.search('side-menu-cat') < 0) {
      this.isSidebarVisible = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isSidebarVisible = false;
  }

  isUserAuthorized() {
    this.subscription.add(this.loginService.isAuthorized$.subscribe(
      (value) => {
        this.isAuth = value;
      }
    ));
  }

  defineRoute() {
    // console.log(this.router);
    this.subscription.add(this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
      map(evt => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe((x: any) => {
        x.layout === true ? this.showLayout = true : this.showLayout = false;
        this.breadCrumb = x.breadCrumb;
      }));
  }

  showSideBar() {
    // let minimizeSidebar = this.userService.minimizeSidebar();
    // console.log(minimizeSidebar);
    //
    // this.cacheService.cacheData();
    // this.commonService.getIdSubject().subscribe((id) => {
    //   console.log('Id from subject', id);
    // })
    // this.minibar = minimizeSidebar;
    // console.log('this.minibar', this.minibar);
  }

  handleSideBar(minibar: any) {
    console.log('sidebar');
    this.isSidebarVisible = true;
  }

  ngOnDestroy() {
    localStorage.removeItem('location_id');
    this.subscription.unsubscribe();
    this.azure.onDestroy();
  }

}
