<div class="_parentMiddle">
  <div class="h-100 d-flex justify-content-center align-items-center">
    <div class="width-full text-center _top-50">
      <i class="bi bi-shield-lock-fill opacity-50" style="font-size: 80px;"></i>
      <h6 class="mt-4 width-full text-center opacity-75">
        Access disabled due to your permissions.<br>
        To request access, contact the administrator.
      </h6>
      <button
        *ngIf="azure.isAuth()"
        [routerLink]="'/logout'"
        class="btn btn-primary mt-4">
        Logout
      </button>
      <button
        *ngIf="!azure.isAuth()"
        [routerLink]="'/login'"
        class="btn btn-primary mt-4">
        Login
      </button>
    </div>
  </div>
</div>
