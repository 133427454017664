import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { ConformationMessageTypes } from '../conformation-message/conformation-message.constants';
import { ConfirmationMessage } from '../conformation-message/modal';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  messageType: string;
  confirmationMessage: ConfirmationMessage;
  messageIsString: boolean = true;

  constructor(
    public snackBarRef: MatSnackBarRef<StatusComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data) { }

  ngOnInit(): void {
    this.confirmationMessage = ConformationMessageTypes[this.data.type];
    this.messageIsString = typeof this.data.message === 'string'; 
  }

  // @HostListener('document:click')
  closeSnackBar() {
    this.snackBarRef.dismiss();
  }

}
