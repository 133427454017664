<input
  type="text"
  id="{{id || ''}}"
  placeholder="Type here..."
  maxlength="50"
  [disabled]="control?.disabled || isDisabled"
  (keyup)="onKeyUp($event)"
  [ngClass]="{'form-control-sm': isSmall, 'ng-pristine ng-invalid ng-touched': !control?.disabled && !control?.valid && control?.touched}"
  class="form-control c-font-c-0 c-font-14 w-80"
  appMaskDecimal
  [isNegative]="isNegative"
  [value]="tmpValue | maskDecimalPipe : isNegative">
