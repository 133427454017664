import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  jobs: number[] = [];
  loading = new BehaviorSubject<boolean>(false);
  loading$ = this.loading.asObservable();

  disableSpinnerIndicator = new BehaviorSubject<boolean>(true);
  disableSpinnerIndicator$ = this.disableSpinnerIndicator.asObservable();

  constructor() { }

  showLoader() {
    this.jobs.push(0);
    this.loading.next(true);
  }

  hideLoader() {
    this.jobs.pop();
    if(this.jobs.length === 0) {
      this.loading.next(false);
    }
  }

  clearJobs() {
    this.jobs = [];
    this.loading.next(false);
  }

  disableSpinner(indicator: boolean) {
    this.disableSpinnerIndicator.next(indicator);
  }

}
