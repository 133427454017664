<div class="container-fluid reports">
  <div *ngIf="!reportTypeId" class="container-fluid text-center p-3">Please select a Report</div>

  <iframe *ngIf="reportTypeId === 1"
          title="Close Fuel Day Report"
          [src]="reports.dailyCloseFuelDayReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 2"
          title="Daily Fuel Reclassification Report"
          [src]="reports.dailyFuelReclassificationReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 3"
          title="Daily Fuel Uplift Report"
          [src]="reports.dailyFuelUpliftReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 4"
          title="Daily Owner Balance Report"
          [src]="reports.dailyOwnerBalanceReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 5"
          title="Daily Start End Meter Report"
          [src]="reports.dailyMeterSkipReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 6"
          title="Daily Stock Transfer Report"
          [src]="reports.dailyStockTransferReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 7"
          title="Daily Unit Balance Report"
          [src]="reports.dailyUnitBalanceReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 8"
          title="Daily Unit Variance Report"
          [src]="reports.dailyUnitVarianceReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 9"
          title="Fuel Receipts Report"
          [src]="reports.dailyFuelReceiptsReport"
          allowFullScreen="true">
  </iframe>

  <iframe *ngIf="reportTypeId === 10"
          title="Fuel Receipts Report"
          [src]="reports.retailFuelSalesSummary"
          allowFullScreen="true">
  </iframe>

  <!-- <iframe *ngIf="reportTypeId === 10"
          title="Retail Fuel sales summary"
          src="https://app.powerbi.com/reportEmbed?reportId=93b002c8-a09c-4e8f-9c46-2a413e8138d2&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3"
          allowFullScreen="true">
  </iframe> -->
</div>
