import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icon'
})
export class IconPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return typeof value === 'object' && Object.keys(value).length < 1 ? false : value == '' ? false : true
  }
}
