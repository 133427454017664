import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  showSpinner = new BehaviorSubject<boolean>(false);
  showSpinner$ = this.showSpinner.asObservable();

  constructor() { }

  show() {
    // this.showSpinner.next(true);
  }

  hide() {
    // this.showSpinner.next(false);
  }

}
