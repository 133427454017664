import { AfterViewChecked, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MaskDecimalService } from '../services/mask-decimal.service';

@Directive({
  selector: '[appMaskDecimal]'
})
export class MaskDecimalDirective implements AfterViewChecked {

  @Input()
  public isNegative: boolean = false;

  init = false;
  isTyped = false;

  constructor(private el: ElementRef,
              private service: MaskDecimalService) {
  }

  ngAfterViewChecked(): void {
    if (this.el.nativeElement.value && !this.init && !this.isTyped) {
      const rounded = this.service.getRounded(this.el.nativeElement.value, this.isNegative);
      let decimal = this.service.getDecimal(this.el.nativeElement.value);
      decimal = decimal.length == 1 && decimal !== '0' ? decimal + '0' : decimal;
      this.el.nativeElement.value =
        `${this.service.replaceThousands(this.service.getRounded(rounded, this.isNegative))}.${decimal || '00'}`;
    }
  }

  @HostListener('keydown', ['$event.target.value'])
  public onKeyDown(): void {
    this.isTyped = true;
  }

  @HostListener('keyup', ['$event.target.value'])
  public onInput(value: string): void {
    if (value) {
      this.el.nativeElement.value = '' + this.service.getResult(value, this.isNegative);
    } else {
      this.el.nativeElement.value = '';
    }
  }

  @HostListener('focusout', ['$event.target.value'])
  public onFocusout(value: string): void {
    if (value && value != '-') {
      const rounded = this.service.getRounded(value, this.isNegative);
      let decimal = this.service.getDecimal(value);
      decimal = decimal.length == 1 && decimal !== '0' ? decimal + '0' : decimal;
      const format = this.service.format(
        `${rounded}.${decimal || '00'}`,
        this.isNegative
      );
      setTimeout(() => {
        this.el.nativeElement.value = '' + `${this.service.replaceThousands(this.service.getRounded(format, this.isNegative))}.${this.service.getDecimal(format)}`;
      }, 0);
    } else {
      this.el.nativeElement.value = '';
    }
  }
}
