import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../../../app.constants';
import { StorageService } from "../../../services/user/storage.service";
import { LoginService } from "../../../services/login.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, pairwise, switchMap, takeUntil, tap, startWith } from "rxjs";
import { Observable, of, Subject } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ModalPopupComponent } from "../modal-popup/modal-popup.component";
import { FuelLocationDate } from "src/app/Modals/fuel-location-date";
import { LocationI } from "src/app/Modals/location-DateI";
import { checkForobj } from "src/app/utils";
import { CommonImportErrorsApiService } from 'src/app/services/common-import-errors-api.service';
import { AzureAdService } from '../../azure/azure-ad.service';
import { UserInfoI } from 'src/app/Modals/user-infoI';
import { FuelClosingColorSerice } from 'src/app/fuel-closing/fuel-closing-color-service';
import { FormChangesDeactivateGuardObject } from 'src/app/unsaved.guard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output() handleSideBar = new EventEmitter<boolean>();
  appName: string = AppConstants.AppName;
  appPath: string = AppConstants.AppPathSeparator;
  userName: string | undefined = '';
  profile = '';
  isAuth = false;
  userInfo: UserInfoI;
  fuelDayData$ = new Observable<any>();
  unsubscribeSubject = new Subject<boolean>();
  locations = [];
  locationsAndDate$: Observable<FuelLocationDate>;
  locationForm: FormGroup;
  previousLocation: LocationI;
  currentLocation: LocationI;
  fuelDay: string = '';
  prevVal: LocationI;
  filteredLocations = [];
  disableLocation: boolean = false;
  initials = '';

  @Input()
  set setBreadcrumb(value: string) {
    this.appPath = AppConstants.AppPathSeparator + value;
  }

  constructor(private storageService: StorageService,
    private loginService: LoginService,
    private router: Router,
    private azure: AzureAdService,
    private dialog: MatDialog,
    private fuelClosingColorSerice: FuelClosingColorSerice,
    private commonImportErrorsApiService: CommonImportErrorsApiService,
    private formChangesDeactivateGuardObject: FormChangesDeactivateGuardObject
  ) {
    this.locationForm = new FormGroup<any>({
      location: new FormControl<any>('', { nonNullable: true, validators: [checkForobj, Validators.required]})
    });
  }


  ngOnInit() {
    if(this.azure.isAuth() && this.storageService.getAzurePayload()?.account) {

      this.locationForm.valueChanges
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(() => this.loginService.setLocationValidity(this.locationForm.valid))

      this.loginService.fuelLocationAndDate$
        .pipe(
          takeUntil(this.unsubscribeSubject)
        ).subscribe((location: LocationI) =>this.fuelDay = location.fuelDay)
      /**
       * listen to the navEnd event, and check the prop to enable and disable location dropdown
       */
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd ),
          map(() => {
            let route: ActivatedRoute = this.router.routerState.root;
            let routeTitle = '';
            while (route!.firstChild) {
              route = route.firstChild;
            }
            if (route.snapshot.data['disableLocation']) {
              routeTitle = route!.snapshot.data['disableLocation'];
            }
            return routeTitle;
          })
        ).subscribe((disableLocation: string) => disableLocation ? this.locationForm.get('location').disable({ emitEvent: false }) : this.locationForm.get('location').enable({ emitEvent: false }));



      this.loginService.userInfo$
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((userInfo) => {
          this.userInfo = userInfo;
          this.initials = this.getProfileInitials();
        });

      this.loginService.isAuthorized$.subscribe(
        (value) => {
          this.isAuth = value;
        }
      )

      /**
       * on page refresh set the userinfo
       * setting default location this needs to be removed
       */
      const azurePayload = this.storageService.getAzurePayload();
      if(azurePayload){
        const {name, username} = azurePayload.account;
        this.loginService.storeUserData({name, username });
      }
      /**
       * get locations list
       * setting default location this needs to be removed
       */
      this.loginService.getLocations(this.userInfo.username)
      .subscribe(d => {
        this.locations = d.content?.items;
        this.filteredLocations = d.content?.items;
        // clean this -> need to remove this once default location impl is done

        /**
         * check if location storage have location id
         * if yes then use that location
         * if not fetch 1st record from the response and set value to location control.
         */
        const storedLocationId = localStorage.getItem('location_id');
        if (storedLocationId) {
          const location = this.getLocationById(storedLocationId);
          this.setLocation(location);
        } else {
          localStorage.setItem('location_id', this.filteredLocations[0].locationId);
          this.setLocation(this.filteredLocations[0]);
        }
      });
      /**
       * listening to location value changes
       * if value is obj the set current, previous locations and display popup to get confirmation from user
       * if not object filter the locations to show in dropdown
       *
       */
      this.locationForm.get('location').valueChanges
        .pipe(
          startWith(undefined as any),
          pairwise(),
          tap(([previousLocation, currentLocation]) => {
            if(this.previousLocation?.locationId != currentLocation?.locationId){
              if (typeof currentLocation === 'object') {
                this.currentLocation = currentLocation;
                this.previousLocation = currentLocation;
                localStorage.setItem('location_id', this.locationForm.get('location').getRawValue().locationId);
                this.router.url !== '/home'  ? this.displayPopup() : this.getFuelDayWithoutPopup();
              } else {
                this.filterList(currentLocation)
              }
            }
          })
        ).subscribe(() => { })
    }
  }

  setLocation(location: LocationI): void {
    this.locationForm.get('location').setValue(location, { emitEvent: false }); // remove
    this.currentLocation = location;
    this.previousLocation = location;
    this.getFuelDayWithoutPopup();
  }

  getLocationById(id: string): LocationI {
    return this.locations.find((location: LocationI) => id === location.locationId)
  }

  showSideBar(): void {
    this.handleSideBar.emit(true);
  }

  filterList(currentLocation) {
    this.filteredLocations = this.locations.filter((location) => location.locationId.toLowerCase().includes(currentLocation.toLowerCase()) || location.description.toLowerCase().includes(currentLocation.toLowerCase()));
  }

  getFuelDayWithoutPopup() {
    this.fuelClosingColorSerice.setSelectedRecordInfo(null);
    this.getFuelDay().subscribe(response => {
      if (response) {
        this.loginService.setFuelLocationAndDate({ ...this.currentLocation, fuelDay: response?.content?.fuelDayModel?.fuelDay });
        this.commonImportErrorsApiService.getCommonImpoerErrorCount(this.currentLocation.locationId);
      }
    })
  }

  displayPopup() {
    let dialogConfg = new MatDialogConfig();
    dialogConfg = {
      data: {
        dialogTitle: 'Warning',
        additionaltext: '',
        dialogDescription: 'Any changes in progress will be lost.  Are you sure you want to set a new location?',
        headerText: '',
        confirmHeader: 'Are you sure?',
        confirmButtonName: 'Yes',
        cancelButtonName: 'No',
        height: '400px',
        width: '600px'
      },
      disableClose: true
    };
    const dialogRef = this.dialog.open(ModalPopupComponent, dialogConfg);
    dialogRef.afterClosed()
      .pipe(
        switchMap((response) => {
          if (!response) {
            this.formChangesDeactivateGuardObject.formDirty = false;
            this.router.navigate(['home']);
            return this.getFuelDay()
          } else {
            this.getLocationbyLocationId();
            this.locationForm.get('location').setValue(this.prevVal, { emitEvent: false });
            this.filteredLocations = this.locations;
            return of(false)
          }
        })
      )
      .subscribe(response => {
        if (response) {
          this.fuelClosingColorSerice.setSelectedRecordInfo(null);
          this.loginService.setFuelLocationAndDate({ ...this.currentLocation, fuelDay: response['content'].fuelDayModel?.fuelDay });
          this.commonImportErrorsApiService.getCommonImpoerErrorCount(this.currentLocation.locationId);
        }
      });
  }

  getFuelDay() {
    return this.loginService.getFuelDay(this.currentLocation['locationId']).pipe(tap(() => this.loginService.setLocationValidity(true)));
  }

  getOptionText(option) {
    return `${option['locationId']}-${option['description']}`;
  }

  getLocationbyLocationId() {
    this.loginService.fuelLocationAndDate$.subscribe((d: LocationI) => this.prevVal = this.locations.find((location: LocationI) => d.locationId === location.locationId))
  }

  getProfileInitials() {
    let nameArray = this.userInfo?.name?.split(' ');
    if(nameArray?.length > 1) {
      return `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`
    } else {
      return '';
    }
    // if (this.profile === null || this.profile === undefined ||
    //   this.profile.name === null || this.profile.name === undefined) {
    //   return "";
    // }
    // var array = this.profile.name.split(/\s+/);
    // if (array.length > 1 && array[0].length > 1 && array[1].length > 1) {
    //   return array[0].charAt(0).toUpperCase() + array[1].charAt(0).toUpperCase();
    // }
    // return "";
  }

  // logout() {
  //   this.storageService.removeData(this.profile);
  // }

  reset() {
    this.filteredLocations = this.locations;
  }

  logout() {
    this.loginService.isUserAuthorized(false);
    this.storageService.removeData('token');
    this.loginService.storeUserData({name: '', username: ''});
    this.router.navigate(['/']);
  }
}
