<span *ngIf="displayCharLength > 0; else withoutTruncate">
    <span [ngbPopover]="rowData?.length > displayCharLength || hoverInfo ? rowDataPopover : null" popoverClass="signature-popover"
        [placement]="placement" triggers="mouseenter:mouseleave"
        [ngClass]="rowData?.length > displayCharLength || hoverInfo ? 'dashed-underline': ''" [container]="'body'">
        <span *ngIf="rowData?.length > displayCharLength">{{rowData | truncate:displayCharLength }}</span>
        <span *ngIf="rowData?.length <= displayCharLength">{{rowData}}</span>
        <ng-template #rowDataPopover>
            <span *ngIf="rowData?.length > displayCharLength else showOnlyId">
                <span *ngIf="hoverInfo">{{hoverInfo}} - {{rowData}}</span>
                <span *ngIf="!hoverInfo">{{rowData}}</span>
            </span>
            <ng-template #showOnlyId>
                {{hoverInfo}}
            </ng-template>
        </ng-template>
    </span>
</span>
<ng-template #withoutTruncate>
    <span [ngbPopover]="rowData ? showPopover : null" popoverClass="signature-popover" [placement]="placement ? placement: 'bottom auto'"
        triggers="mouseenter:mouseleave" ngClass="{{rowData ? 'dashed-underline': ''}}">
        <span>{{rowData ? rowData : appConstants.emptyValue}}</span>
    </span>
    <ng-template #showPopover>{{hoverData ? hoverData : appConstants.emptyValue}}</ng-template>
</ng-template>