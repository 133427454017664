import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '../../services/user/storage.service';
import { LoginService } from '../../services/login.service';

@Injectable({
  providedIn: 'root'
})

export class AzureAdService {
  private roles: string[];
  private timer?;
  private readonly destroying = new Subject<void>();

  readonly KEYS = {
    azureRequestPayload: `azureRequestPayload`,
  };

  constructor(public auth: MsalService,
              private storage: StorageService,
              private broadcastService: MsalBroadcastService,
              private msal: MsalBroadcastService,
              private loginService: LoginService,
              private router: Router,
              private http: HttpClient,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
  }

  isAuth(): boolean {
    return this.auth?.instance?.getAllAccounts()?.length > 0;
  }

  isRoleAllow(ids: string | string[]): boolean {
    const roles = this.getRoles();
    let result = false;
    if (Array.isArray(ids)) {
      let count = 0;
      if (ids?.length && this.roles?.length) {
        ids.forEach((title: string) => {
          if (this.roles.find(x => x.toLowerCase() === title.toLowerCase())) {
            count++;
          }
        });
      }
      result = count > 0;
    } else if (typeof ids === 'string') {
      roles?.forEach((el: string) => {
        if (el.toLowerCase() === ids?.toLowerCase()) {
          result = true;
        }
      });
    }
    return result;
  }

  getRoles(): string[] {
    if (this.roles?.length) {
      return this.roles;
    }
    const parseJwt = (tokenId: string) => {
      try {
        const base64Url = tokenId.split('.')[1];
        const base64 = base64Url
          .replace(/-/g, '+')
          .replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map((c: string) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }
            )
            .join('')
        );
        return JSON.parse(jsonPayload);
      } catch {
      }
    };
    const token = this.storage.getAzureToken();
    const jwt = parseJwt(token || '');
    return this.roles = jwt?.roles || [];
  }

  login(): void {
    this.auth.loginRedirect();
  }

  logout(): void {
    this.auth.logoutRedirect({
      postLogoutRedirectUri: environment.azure.redirectUrl
    });
  }

  onInit(): void {
    this.onSuccess();
    this.onProgress();
    this.refreshToken();
  }

  onDestroy(): void {
    clearTimeout(this.timer);
    this.destroying.next(undefined);
    this.destroying.complete();
  }

  onSuccess(): void {
    this.msal.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe(async (result: EventMessage) => {
        this.storage.setData(this.KEYS.azureRequestPayload, result.payload);
      });
  }

  async redirectOnSuccess(isHard = false): Promise<void> {
    if (isHard || !this.isAuth()) {
      if (this.isAuth()) {
        await this.getTokenSilent();
      }
      window.location.href = environment.azure.homePage;
    }
  }

  private refreshToken(): void {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await this.getTokenSilent();
      this.refreshToken();
    }, 60000 * 10);
  }

  private async getTokenSilent(): Promise<AuthenticationResult> {
    const requestObj = this.storage.getData(this.KEYS.azureRequestPayload);
    const tokenObj = await this.auth.instance.acquireTokenSilent({
      account: requestObj.account || null,
      authority: environment.azure.authority,
      scopes: [environment.azure.scope.entries, environment.azure.scope.iterable],
    });
    this.storage.saveAzurePayload(tokenObj);
    const {name, username} = tokenObj.account;
    this.loginService.storeUserData({name, username });
    return tokenObj;
  }

  private onProgress(): void {
    this.msal.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(async () => {
        // await this.redirectOnProgress();
      });
  }

}


