<div
  class="card border-0"
  [ngClass]="{'mb-3': isMarginBottom}">
  <div class="card-body">
    <div class="mar-l-15 mar-r-15">
      <div
        *ngIf="source?.data?.length && isPagination"
        class="row">
        <div class="col-12">
          <div class="float-end _pagSize">
            <span class="badge rounded-pill text-bg-secondary">
              {{(source?.paginator?.pageIndex) * (source?.paginator?.pageSize) > 0
              ? (source?.paginator?.pageIndex) * (source?.paginator?.pageSize) + 1
              : 1
              }}
              -
              {{(source?.paginator?.pageIndex + 1) * (source?.paginator?.pageSize) > source.data.length
              ? source.data.length
              : (source?.paginator?.pageIndex + 1) * (source?.paginator?.pageSize)
              }}
              of
              {{source.data.length}}
            </span>
          </div>
        </div>
      </div>
      <div
        *ngIf="columns?.length"
        class="signature-table">
        <table
          mat-table
          matSort
          [dataSource]="source"
          matSortActive="{{sortBy}}"
          matSortDirection="desc"
          class="mat-elevation-z0">
          <ng-container
            *ngFor="let column of columns; let i = index"
            matColumnDef="{{column.field}}">
            <th
              [i]="i"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              [appResizeColumn]="isResize"
              class="mat-move-selector"
              [ngClass]="{isResize: isResize}"
              [disabled]="!!column?.checkbox">
              <div class="width-full">
                {{column.label}}
              </div>
              <div
                class="pl-2 pr-2 width-full text-left"
                *ngIf="column?.checkbox;">
                <mat-checkbox
                  [checked]="status"
                  (change)="column?.subscribe(null, column, $event, i)">
                </mat-checkbox>
              </div>
            </th>
            <ng-container *matCellDef="let element; let i = index">
              <td
                mat-cell
                class="pr-2 pl-2">
                <ng-template *ngIf="column?.date; else actions">
                  <span
                    class="{{column?.each ? column?.each(element[column?.field], element) : ''}}">
                    {{element[column.field] | date: '' + column?.date }}
                  </span>
                </ng-template>
                <ng-template #actions>
                  <ng-container *ngIf="element?.actions; else checkbox">
                    <div class="table-col-icons">
                    <span *ngFor="let btn of element?.actions">
                    <span class="tooltip-c">
                      <i (click)="onAction($event, element, btn)" [ngClass]="btn.icon"></i>
                      <span class="tooltiptext">
                        {{btn.text}}
                      </span>
                    </span>
                  </span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #checkbox>
                  <ng-container
                    class="text-left"
                    *ngIf="column?.checkbox; else def">
                    <section
                      class="middle-pos">
                      <mat-checkbox
                        [ngClass]="{'opacity-25': (column?.checkbox && column?.each ? !column?.each(column?.field, element) : false)}"
                        [disabled]="column?.disabled"
                        [(ngModel)]="element[column.field]"
                        (click)="$event?.stopPropagation();"
                        (change)="column?.subscribe(element, column, $event, i);">
                      </mat-checkbox>
                    </section>
                  </ng-container>
                </ng-template>
                <ng-template #def>
                  <span class="{{column?.each ? column?.each(element[column?.field], element) : ''}} fit-content">
                    {{element?.transform
                    ? element.transform(element[column.field])
                    : element[column.field]
                    }}
                  </span>
                </ng-template>
              </td>
            </ng-container>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="columnArr">
          </tr>
          <tr
            mat-row
            (click)="onRecord(row, i)"
            *matRowDef="let row; let i = index; let even = even; columns: columnArr;"
            [ngClass]="{'selected': index === i || row?.selected}">
          </tr>
        </table>
      </div>
      <mat-paginator
        *ngIf="isPagination"
        [hidePageSize]="false"
        [showFirstLastButtons]="true"
        [pageSizeOptions]="pagination">
      </mat-paginator>
    </div>
  </div>
</div>
