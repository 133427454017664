import { Injectable } from '@angular/core';
import { UnitI, Transaction } from '../Modals/closing-gridI';
import { LocationI } from '../Modals/location-DateI';
import { ConversionFactor } from '../Modals/NetConversionFactor';
import { FuelClosingConstants } from './fuel-closing.constants';
import { BehaviorSubject } from 'rxjs';
import { checkIfValueIsNullOrUndefined, getISOString } from '../utils';
import * as moment from 'moment';
import { AppConstants } from '../app.constants';

@Injectable({
    providedIn: 'root'
  })
  
  export class FuelClosingColorSerice {

    colorCodes = FuelClosingConstants.ColorCodes;
    private selectedRecord = new BehaviorSubject<any>(null);
    selectedRecord$ = this.selectedRecord.asObservable();
    rowIn = null;

    setSelectedRecordInfo(info) {
      this.rowIn = info;
      this.selectedRecord.next(info)
    }


  getUnitStatusColorCode(element: UnitI, locationAndFuelDay: LocationI, netConversions: ConversionFactor[]): UnitI {

    let unitColorCode: string = "";
    if (element.transactions.length > 0) {
      unitColorCode =
        this.checkColorExistsSequenceWise(element.transactions, this.colorCodes.Blue) ? this.colorCodes.Blue :
          this.checkColorExistsSequenceWise(element.transactions, this.colorCodes.Red1) ? this.colorCodes.Red1 :
            this.checkColorExistsSequenceWise(element.transactions, this.colorCodes.Red2) ? this.colorCodes.Red2 :
              this.checkColorExistsSequenceWise(element.transactions, this.colorCodes.Orange) ? this.colorCodes.Orange :
                this.checkColorExistsSequenceWise(element.transactions, this.colorCodes.Yellow) ? this.colorCodes.Yellow :
                  this.checkThresholdValue(this.calculateVariancePercentage(element)) ? this.colorCodes.Black :
                    this.colorCodes.Green;
    } else {
      unitColorCode = this.checkThresholdValue(this.calculateVariancePercentage(element)) ? this.colorCodes.Black :  this.colorCodes.Green;
    }

    return {
      ...element,
      unitColorCode: unitColorCode,
      checked: false,
      unitRowData: `${element.unitId}-${element.unitNumber}`,
      isExpanded: element.unitId == this.rowIn && element.transactions.length > 0,
      checkBoxDisabled: 
      element.unitStatus.toLocaleLowerCase() === 'closed' ? this.checkConditionForClosedUnit(unitColorCode) : this.checkConditionForOpenUnit(unitColorCode, element, locationAndFuelDay, netConversions)
    };
  }

  checkThresholdValue(value: number): boolean {
    return value && (value < FuelClosingConstants.minVarianceThreshold || value > FuelClosingConstants.maxVarianceThreshold) ? true : false
  }

  calculateVariancePercentage(element): number {
    if(checkIfValueIsNullOrUndefined(element?.variance)) {
      return ((element.physicalInventory - element.bookInventory)/element.capacity)*100;
    }
  }

  checkConditionForOpenUnit(unitColorCode: string, element: UnitI, locationAndFuelDay: LocationI, netConversions: ConversionFactor[]): boolean {
    // // TODO - clean this later
    // console.log('*******Checkbox logic**************');
    // console.log('unitId::',element.unitId);
    // console.log('color-code-condition::',this.checkForColorCodes(unitColorCode));
    // // console.log('Physical inventory available::', element.capacity > 0 ? this.checkIfPIisNull(element.physicalInventory) : true);
    // console.log('net conversions::', locationAndFuelDay.isNet ? this.checkNetConversionFactors(element, netConversions) : true);
    // console.log('Final condition',this.checkForColorCodes(unitColorCode) && element.capacity > 0 ? !!element.physicalInventory : true && (locationAndFuelDay.isNet ? this.checkNetConversionFactors(element, netConversions) : true))
    return this.checkForColorCodes(unitColorCode) && (locationAndFuelDay.isNet ? this.checkNetConversionFactors(element, netConversions, true) : true);
  }

  checkIfPIisNull(physicalInventory: number): boolean {
    return (physicalInventory != null && physicalInventory != undefined && physicalInventory > 0)
  }
 
  checkNetConversionFactors(element: UnitI, netConversions: ConversionFactor[], showLogs= false): boolean {
    // TODO - clean this later
    if(showLogs) {
      // console.log('**********Net Conversion Factor*************');
      // console.log('fuelType::',element.fuelType);
      // console.log('unitType::',element.unitType);
    }
    if(element.fuelType === 'JTA' && element.unitType=== 'FTN') {
      // console.log('Checking in net conversions list and return value');
      const record = netConversions.find((netConversion => netConversion?.unitId === element?.unitId));
      // console.log('eligible fon NCF::', !!record?.temperature && !!record?.gravity && !!record?.volumeCorrectionFactor);
      return !!record?.temperature && !!record?.gravity && !!record?.volumeCorrectionFactor;
    } else {
      // console.log('Not Checking in net conversions list and always return true for net conversions check');
      // console.log('eligible fon NCF::', true);
      return true
    }
  }

  checkConditionForClosedUnit(unitColorCode: string): boolean {
    return this.checkForColorCodes(unitColorCode);
  }

  checkForColorCodes(unitColorCode: string): boolean {
    return unitColorCode === FuelClosingConstants.ColorCodes.Green || unitColorCode === FuelClosingConstants.ColorCodes.Yellow || unitColorCode === FuelClosingConstants.ColorCodes.Orange || unitColorCode === FuelClosingConstants.ColorCodes.Black  || unitColorCode === FuelClosingConstants.ColorCodes.Red1 || unitColorCode === FuelClosingConstants.ColorCodes.Red2;
  }


  /**
   * @param records Fuel Closing records.
   *  
   */
  getTransStatusColorCode(records: UnitI[], locationAndFuelDay: LocationI, netConversions: ConversionFactor[], fuelDay: any) {
    /** 
     * @param updatedFuelClosingRecords updated color codes records
    */
    let updatedFuelClosingRecords: UnitI[] = [];

    /**
     * @param transactionsArr holds all the transactions records for that location.
     */
    let transactionsArr = [];
    
    records.forEach((element) => {
      element.transactions.map((transaction : Transaction) => {transactionsArr.push(transaction.asr)});
    });
    transactionsArr = [...new Set(transactionsArr)];
    records.forEach((element, index) => {
      let tempTransactionsArr = []; // holds current unit's transactions

      tempTransactionsArr = element?.transactions;

      tempTransactionsArr.forEach((currentTransaction : Transaction, transIndex) => {
        if(currentTransaction?.correctionStatus !== 'R') {
          if(this.checkIfRecordIsEligibkeForBlue(currentTransaction)) {
            currentTransaction.transactionColorCode = this.colorCodes.Blue;
          }
          
          else if(currentTransaction?.correctionStatus !== 'C') {
            // for non auto reversal transaction.
            if(!currentTransaction?.isAutoReverse) {
              //  Check RED & YELLOW color based on previous meter reading differce with current meter reading.

              let currentTransactionMeterNumber = this.checkMeterNumber(currentTransaction.meter1Start, currentTransaction.meter1End, currentTransaction.meter2Start, currentTransaction.meter2End);
              let previousTransaction = null;
              let meter1Reading: number = null;
              let meter2Reading: number = null;
              let previousTransactionMeterNumber: number = null;
              let isParentRequired: boolean = true;
              for (let nextIndex = transIndex + 1; nextIndex < tempTransactionsArr.length; nextIndex++) {
                  previousTransaction = tempTransactionsArr[nextIndex];
                  previousTransactionMeterNumber = this.checkMeterNumber(previousTransaction.meter1Start, previousTransaction.meter1End, previousTransaction.meter2Start, previousTransaction.meter2End);
                  if (currentTransactionMeterNumber === previousTransactionMeterNumber && (previousTransaction?.correctionStatus !== 'R' && previousTransaction?.correctionStatus !== 'C' && !previousTransaction?.isAutoReverse)) {
                      isParentRequired = false;
                      meter1Reading = previousTransaction.meter1End;
                      meter2Reading = previousTransaction.meter2End;
                      break;
                  }
              }

              //If last transaction then compare meter readings with parent unit.
              if ((tempTransactionsArr.length - 1) === transIndex || isParentRequired) {
                previousTransaction = element;
                meter1Reading = previousTransaction.meter1EndReading;
                meter2Reading = previousTransaction.meter2EndReading;
              }

              //  If difference of current meter start and previous meter end reading is greater than 2 then its RED.
              const redData = this.isRedColor(currentTransaction, fuelDay, meter1Reading, meter2Reading );
              if(redData.isRed) {
                currentTransaction.transactionColorCode = redData.redColorCode
              }
              /**
             * Check ORANGE color based on SAME ASR from API property isDuplicateASR.
             */
              else if(currentTransaction?.isDuplicateASR) {
                currentTransaction.transactionColorCode = this.colorCodes.Orange;
              }
              // Check YELLOW color based on previous meter reading differce with current meter reading.
              // If difference of meter readings is equal to 1 then its YELLOW.
              else if(this.isYellowColor(currentTransaction, meter1Reading, meter2Reading)) {
                currentTransaction.transactionColorCode = this.colorCodes.Yellow;
              }
              else {
                currentTransaction.transactionColorCode = this.colorCodes.Green;
              }
            } else {
              // auto reversal transaction.
              currentTransaction.transactionColorCode = this.colorCodes.Green;
            }
          } else {
            if(currentTransaction?.correctionStatus === 'C') {
              currentTransaction.transactionColorCode = this.colorCodes.White;
            }
          } 
        }
        
      });

      if(tempTransactionsArr.length > 0) {
        element.transactions = tempTransactionsArr;
      }
      /**
       * Updating the colors for unit based on above transaction color coding.
       */
      updatedFuelClosingRecords.push(this.getUnitStatusColorCode(element, locationAndFuelDay, netConversions));
    });
    return updatedFuelClosingRecords;
  }

  isRedColor(transaction: any, fuelDay: any, meter1Reading: number, meter2Reading: number): { isRed: boolean, redColorCode: string } {

    if ((transaction.meter1Start !== null && transaction.meter1End !== null) || (transaction.meter2Start !== null && transaction.meter2End !== 0)) {
        let meter1Difference = transaction.meter1Start !== null && transaction.meter1End !== null && meter1Reading !== null ? Math.abs(transaction.meter1Start - meter1Reading) : null;
        let meter2Difference = transaction.meter2Start !== null && transaction.meter2End !== null && meter2Reading !== null ? Math.abs(transaction.meter2Start - meter2Reading) : null;

        if (meter1Difference !== null || meter2Difference !== null) {
            if (meter1Difference >= 2 || meter2Difference >= 2) {
                let transactionDate = moment(transaction.serviceDatetime);
                let fuelDate = moment(fuelDay);
                return {
                    isRed: true,
                    redColorCode: transactionDate.isBefore(fuelDate, 'day') ? this.colorCodes.Red1 : this.colorCodes.Red2
                };
            } else {
                return { isRed: false, redColorCode: '' };
            }
        } else {
            return { isRed: false, redColorCode: '' };
        }
    } else {
        return { isRed: false, redColorCode: '' };
    }
  }

  isYellowColor(transaction: any, meter1Reading: number, meter2Reading: number): boolean {
    if ((transaction.meter1Start !== null && transaction.meter1End !== null) || (transaction.meter2Start !== null && transaction.meter2End !== 0)) {
        let meter1Difference = transaction.meter1Start !== null && transaction.meter1End !== null && meter1Reading !== null ? Math.abs(transaction.meter1Start - meter1Reading) : null;
        let meter2Difference = transaction.meter2Start !== null && transaction.meter2End !== null && meter2Reading !== null ? Math.abs(transaction.meter2Start - meter2Reading) : null;

        if (meter1Difference !== null || meter2Difference !== null) {
            if (meter1Difference === 1 || meter2Difference === 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
  }

  checkMeterNumber(meter1Start, meter1End, meter2Start, meter2End) {
    let meterNumber = null;
    if(meter1Start !== null && meter1End !== null) {
      meterNumber = 1;
    } else if(meter2Start !== null && meter2End !== null) {
      meterNumber = 2;
    } 
    return meterNumber
  }

  setUnitColorCode(element: Transaction, unitColorCode: string) {
    switch (element.transactionColorCode) {
      case this.colorCodes.Orange:
        unitColorCode = this.colorCodes.Orange
        break;
      case this.colorCodes.Yellow:
        unitColorCode = this.colorCodes.Yellow
        break;
      case this.colorCodes.Red1:
        unitColorCode = this.colorCodes.Red1
        break;
      case this.colorCodes.Red2:
        unitColorCode = this.colorCodes.Red2
        break;
      case this.colorCodes.Blue:
        unitColorCode = this.colorCodes.Blue
        break;
      default:
        unitColorCode = this.colorCodes.Green
        break;
    }
    return unitColorCode;
  }

  /**
     * @param records Fuel Closing records.
     * @param transaction current transaction whose color code is going to be derived.
     * @param index index of current unit
     * @param transIndex index of current transaction
     */
  checkASRInSameUnitOrInDifferent(records: UnitI[], transaction: Transaction, index: number, transIndex: number): boolean {
    let count = 0;
    let tempRecords = records.slice(0); //copy 
    //check within same unit the occurance of same ASR in transactions array 
    tempRecords[index].transactions.forEach((element, i) => {
      if(i !== transIndex) {
        if(element?.asr === transaction?.asr) {
          count+=1;
        }
      }
    });
    if(count > 0) {
      return true;
    } else {
      tempRecords.splice(index, 1);
      return tempRecords.some((unit) => {
        let tempTransactionsArr = [];
        let tempTransactionprev: Transaction = null;
        tempTransactionsArr = unit?.transactions; 
        return tempTransactionsArr.forEach((transItem, index) => {
          let isTTTTransaction = transItem?.transactionType === 'TTT';
          index != tempTransactionsArr?.length - 1  ? tempTransactionprev = tempTransactionsArr[index+1] : tempTransactionprev = tempTransactionsArr[tempTransactionsArr?.length - 1];
          return transItem?.asr === tempTransactionprev?.asr && !isTTTTransaction;
        });
      })
    }
  }

  checkColorExistsSequenceWise(transactions: Transaction[], color: FuelClosingConstants.ColorCodes): boolean {
    return transactions.some((item) =>  item.transactionColorCode === color)
  }

  /**
   * 
   * @param transaction Transaction
   * @returns 
   */
  checkIfRecordIsEligibkeForBlue(transaction: Transaction): boolean {
    return transaction.fimTransactionType === FuelClosingConstants.DISBURSEMENT && (!transaction?.isOwnerMappingCorrect || !transaction?.isCustomerTailNumberCorrect || !transaction?.isFuelTypeCorrect);
  }

}
