import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Broadcaster } from '../../services/broadcaster/broadcaster';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit, OnDestroy {
  @Input() form;
  @Input() fieldName;
  time = {
    hour: 12,
    minute: 0
  };
  meridian = true;
  subscription = new Subscription();

  constructor(private broadcaster: Broadcaster) {
  }

  ngOnInit(): void {

    this.subscription.add(
      this.broadcaster.on<string>(Broadcaster.KEYS.timePickerData).subscribe((fieldName: string) => {
        if (this.fieldName === fieldName) {
          this.onTimeChange();
        }
      })
    );
    this.subscription.add(
      this.broadcaster.on<string>(Broadcaster.KEYS.timePickerSetTime).subscribe((fieldName: string) => {
        if (this.fieldName === fieldName) {
          this.setTime();
        }
      })
    );
  }

  setTime(): void {
    const input = this.form.get(this.fieldName);
    if (input && input.value) {
      this.time = {
        hour: input.value.hour(),
        minute: input.value.minutes()
      };
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onTimeChange(): void {
    const input = this.form.get(this.fieldName);
    input.value.set(this.time);
    input.setValue(input.value);
  }

}
