import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalModule} from '@azure/msal-angular';
import {PublicClientApplication} from '@azure/msal-browser';
import { GroupsConstants } from './shared/authentication/groups.constants';
import {HomeComponent} from './home/home.component';
import { AllocationsGuard } from './allocations.guard';
import { GroupsGuard } from './shared/authentication/groups.guard';
import { breadCrumbs } from './shared/constants/breadcrumbs.constants';
import { AppVersionComponent } from './app-version/app-version.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, data: {layout: true, breadCrumb: breadCrumbs.home}, canActivate: [GroupsGuard]},
  {
    path: 'importerrors',
    loadChildren: () => import('./correct-import-errors/correct-import-errors.module').then(m => m.CorrectImportErrorsModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.CORRECT_IMPORT_GROUPS }
  },
  {
    path: 'receiving',
    loadChildren: () => import('./fuel-receiving/fuel-receiving.module').then(m => m.FuelReceivingModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.RECEIPT_ENTRY_GROUPS }
  },
  {
    path: 'disbursement',
    loadChildren: () => import('./fuel-disbursement/fuel-disbursement.module').then(m => m.FuelDisbursementModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.DISBURSEMENT_ENTRY_GROUPS }
  },
  {
    path: 'reconciliation',
    loadChildren: () => import('./fuel-reconciliation/fuel-reconciliation.module').then(m => m.FuelReconciliationModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.RECONCILIATION_GROUPS }
  },
  {
    path: 'closing',
    loadChildren: () => import('./fuel-closing/fuel-closing.module').then(m => m.FuelClosingModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.RECONCILIATION_GROUPS }
  },
  {
    path: 'searchtransaction',
    loadChildren: () => import('./search-transaction/search-transaction.module').then(m => m.SearchTransactionModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.SEARCH_TRANSACTIONS_GROUPS }
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.REPORT_SCREEN_GROUPS }
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.ADMIN_SCREEN_GROUPS }
  },
  {
    path: 'allocations',
    loadChildren: () => import('./allocations/allocations.module').then(m => m.AllocationsModule),
    canActivate: [AllocationsGuard],
    data: { groups: GroupsConstants.ALLOCATIONS_GROUPS }
  },
  {
    path: 'reopen-fuelday',
    loadChildren: () => import('./reopen-fuel-day/reopen-fuel-day.module').then(m => m.ReopenFuelDayModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.REOPEN_PREVIOUS_FUEL_DAY_GROUPS }
  },
  {
    path: 'reviewforbilling',
    loadChildren: () => import('./fuelday-review-for-billing/fuelday-review-for-billing.module').then(m => m.ReviewForBillingModule),
    canActivate: [GroupsGuard],
    data: { groups: GroupsConstants.RECONCILIATION_GROUPS }
  },
  {
    path: 'version', component: AppVersionComponent, canActivate: [GroupsGuard]
  }
];

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: '',
        authority: '',
        redirectUri: ''
      },
      cache: {
        cacheLocation: 'localStorage',
      }
    }), null, null)
  ],
  exports: [
    RouterModule,
    MsalModule
  ]

})
export class AppRoutingModule {
}
