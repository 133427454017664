import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayValuesMultiSelectionDropdown',
  pure: false
})
export class DisplayValuesMultiSelectionDropdownPipe implements PipeTransform {

  transform(inputValue: string | string[], charLength: number): string {
    if (!inputValue || inputValue === '' || !charLength) {
      return null;
    }
    if (inputValue && Array.isArray(inputValue) && inputValue.length > 0) {
      if (inputValue.join(', ').length <= charLength) {
        return inputValue.join(', ');
      } else {
        for (let i = 0; i < inputValue.length; i++) {
          const value = inputValue.slice(0, i+1).join(', ');
          if(value.length >= 0) {
            const elementsRemaining = value.charAt(charLength -1) === ',' || value.charAt(charLength -2) === '' ? inputValue.length-i : inputValue.length-i-1;
            return elementsRemaining > 0 ? `${value.substring(0,charLength)}...(+${elementsRemaining})` : `${value.substring(0, charLength)}...`;
          }
        }
      }
    }
  }


}
