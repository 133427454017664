import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'no-records-found',
  templateUrl: './no-records-found.component.html',
  styleUrls: ['./no-records-found.component.css']
})
export class NoRecordsFoundComponent {

  @Input() message?: string = 'No Records Found.';
  @Input() icon?: string = 'bi bi-search';

  constructor() { }

}
