<div
  class="container-fluid p-0 m-0" style="position: relative;">
  <input
    id="typeahead-focus"
    type="text"
    placeholder="Type here"
    class="form-control container-fluid"
    [(ngModel)]="value"
    [disabled]="isDisabled"
    (selectItem)="onChange($event)"
    [ngbTypeahead]="search"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    #instance="ngbTypeahead"
  />
  <i
    *ngIf="value && !isDisabled"
    (click)="reset(); click$.next('')"
    class="bi bi-x-circle pos-absolute mt-3-xicon _chevron cursor-pointer"></i>
  <i
    *ngIf="!value && !isDisabled"
    (click)="click$.next('')"
    class="bi bi-chevron-down pos-absolute mt-2 _chevron cursor-pointer">
  </i>
</div>
