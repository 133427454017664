import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AzureAdService } from '../azure/azure-ad.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsGuard  {

  constructor(private azure: AzureAdService,
              private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const roles: string[] = route.data.groups;
    if (!this.azure.isAuth() || (roles?.length && !this.azure.isRoleAllow(roles))) {
      this.router.navigateByUrl('/login');
    }
    return true;
  }
}
