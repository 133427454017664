import { ApiConstants } from "./services/api.constant";

export class AppConstants {
    public static AppName: string = "Fuel Inventory Management";
    public static AppPathSeparator: string = " / ";
    public static LocalStorage_ManageMenuKey = "collapseManage";
    public static readonly emptyValue = '-';
    public static readonly numberReqex = /\d/;
    public static readonly specialCharReqex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    public static readonly alphaNumericRegex = /^[A-Za-z0-9\s]*$/;
    public static readonly stockTransferransactionTypeFlag = 2;
    public static readonly disbursementTransactionTypeFlag = 1;
    public static readonly searchTransactionTypeFlag = 4;
    public static readonly customerOwnerMappingFlag = 5;
    public static readonly successMessage = 'done';
    public static readonly errorMessage = 'error';
    public static readonly warningMessage = 'warning';
    public static readonly unitClosedWarningMessage = 'Unit is closed. Please reopen the unit.';
    public static readonly unitApiCallFailedMessage = 'Unit status check API call Failed. Please try again later.';
    public static readonly deleteApiCallFailedMessage = 'Delete API call Failed. Please try again later.';

    public static readonly defaultMessage = 'Select search criteria to view results.';
    public static readonly defaultSearchIcon = 'bi bi-search';
    public static readonly unitsCloseMessage = 'All the current day fuel units must be Closed before you can use this screen';
    public static readonly rolesNotAvailableMessage = 'Access disabled due to your permissions. To request access, contact the administrator.';
    public static readonly HOME = 'Home';

    public static readonly DATE_FORMAT = {
      parse: {
          dateInput: 'MM/DD/YYYY'
      },
      display: {
          dateInput: 'MM/DD/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMM YYYY'
      }
  };

  public static readonly DATE_TIME_FORMAT = {
    parse: {
      dateInput: 'MM/DD/YYYY, hh:mm a'
    },
    display: {
      dateInput: 'MM/DD/YYYY, hh:mm a',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
      enableMeridian: true,
    }
  };

  public static readonly TIME_SEC_DATE_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'MM/DD/YYYY HH:mm:ss',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

    public static AppDateFormat: string = 'MMM dd, yy, HH:mm a';

    public static TEXT_NA = 'NA';

    public static gridPageSizeArray = [100];
    public static gridDefaultPageSize = 100;
    public static horizontalPosition  = 'end';
    public static verticalposition = 'top';
    public static timeout = 3000;
    public static readonly slashDateFormat = 'MM/DD/yyyy';
    public static readonly slashDateTimeFormat = 'MM/DD/yyyy HH:MM';
    public static readonly slashDateWithYearStart = 'YYYY-MM-DD';
    // add url's in this array that we dont need spinners
    public static readonly urlsNotToShowSpinner = [
      ApiConstants.duplicateDocumentApiUrl
    ];

}

// // TransactionTicketTypeFlag as below
// // TransactionTicketTypeFlag = 0-- receipts
// // TransactionTicketTypeFlag = 1-- Disbursement
// // TransactionTicketTypeFlag = 2-- Stock Transfer

export enum TransactionTicketTypeFlag
{
  Receipt = 0,
  Disbursement = 1,
  StockTransfer = 2
}
