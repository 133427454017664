import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToNumber'
})
export class StringToNumberPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): number {
    const formattedNumberStr = value.toFixed(2); // Format the number to two decimal places
    return parseFloat(formattedNumberStr);
  }

}
