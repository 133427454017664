import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs';
import { Injectable } from '@angular/core';

interface BroadcastEvent {
  key: any;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class Broadcaster {

  static KEYS = {
    breadCrumb: 'breadCrumbData',
    detailCompany: 'detailCompany',
    timePickerData: 'timePickerData',
    formTouched: 'formTouched',
    timePickerSetTime: 'timePickerSetTime',
  };

  private eventBus: Subject<BroadcastEvent>;

  constructor() {
    this.eventBus = new Subject<BroadcastEvent>();
  }

  emit(key: any, data?: any): void {
    this.eventBus.next({ key, data });
  }

  on<T>(key: any): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter(event => event.key === key)
    ).pipe(
      map(event => event.data as T)
    );
  }
}
