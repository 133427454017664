import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConformationMessageComponent } from './shared/compponents/conformation-message/conformation-message.component';
import {NgbPopoverModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { PopOverComponent } from './shared/compponents/pop-over/pop-over.component';
import { commaSeperatedTwoDecimalsDirective } from './shared/directives/commaSeperatedTwoDecimalsOnly.directive';
import { UppercaseInputDirective } from './shared/directives/convert-to-uppercase.directive';
@NgModule({
  declarations: [
    ConformationMessageComponent,
    PopOverComponent,
    TruncatePipe,
    commaSeperatedTwoDecimalsDirective,
    UppercaseInputDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbPopoverModule,
    NgbTooltipModule,
    ReactiveFormsModule
  ],

  exports: [
    CommonModule,
    FormsModule,
    NgbPopoverModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    ConformationMessageComponent,
    TruncatePipe,
    PopOverComponent,
    commaSeperatedTwoDecimalsDirective,
    UppercaseInputDirective
  ]
})
export class CommonsModule { }
