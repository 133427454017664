import { Directive, HostListener, Self, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';


@Directive({
  selector: '[commaSeperatedTwoDecimals]'
})
export class commaSeperatedTwoDecimalsDirective implements OnDestroy {

  private formatter: Intl.NumberFormat;
  private destroy$ = new Subject();

  constructor(@Self() private ngControl: NgControl) {
    this.formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2});
  }

  ngAfterViewInit() {
    if(this.ngControl.value != null && this.ngControl.value != '') {
      this.setValue(this.formatPrice(this.ngControl.value))
    }
    this.ngControl
      .control
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.updateValue.bind(this));
  }

  updateValue(value) {
    let inputVal = value;
    if(inputVal != null && inputVal != '') {
      inputVal = inputVal.toString();
      this.setValue(!!inputVal ?
        this.validateDecimalValue(inputVal.replace(/[^-0-9.]/g, '')) : '');
    }

  }

  @HostListener('focus') onFocus() {
    if(this.ngControl.value != null && this.ngControl.value != '') {
      this.setValue(this.unformatValue(this.ngControl.value));
    }
  }

  @HostListener('blur') onBlur() {
    let value = this.ngControl.value;
    if(this.ngControl.value != null && this.ngControl.value != '') {
      let splitInput = value.split('.');

      if(splitInput.length == 1) {
        value = (Math.round(splitInput[0] * 100) / 100).toFixed(2);
      }
      !!value && this.setValue(this.formatPrice(value));
    }
  }

  formatPrice(v) {
    return this.formatter.format(v);
  }

  unformatValue(v) {
    return v.replace(/,/g, '');
  }

  validateDecimalValue(v) {
    let isNegative = false;

    if(v[0] == '-') {
      let subStr = v.substring(1,v.length+1);
      isNegative = true;
      v = subStr;
    }

    // Check to see if the value is a valid number or not
    if (Number.isNaN(Number(v))) {
      // strip out last char as this would have made the value invalid
      const strippedValue = v.slice(0, v.length - 1);

      // if value is still invalid, then this would be copy/paste scenario
      // and in such case we simply set the value to empty
      return Number.isNaN(Number(strippedValue)) ? '' : strippedValue;
    }

    // v = this.validateNumberLimit(v);
    if(isNegative) {
      v = '-'+v;
    }
    return v;
  }

  validateNumberLimit(v) {
    if(v.split('.').length == 2) {
      let value = v.split('.')[0];
      let decimalValue = v.split('.')[1];
      if(decimalValue.length > 2) {
        decimalValue = decimalValue.substring(0,2); //only 2 decimal digits.
      }
      if(value.length > 8) {
        value = value.substring(0, 8); //only 8 numeric digits
      }
      return value + '.' + decimalValue;
    }
    if(v.length > 8) {
      return v.substring(0, 8); //only 8 numeric digits
    }
    return v;
  }

  setValue(v) {
    this.ngControl.control.setValue(parseFloat(v).toFixed(2) , { emitEvent: false })
  }

  ngOnDestroy() {
    if(this.ngControl.value != null && this.ngControl.value != '') {
      this.setValue(this.unformatValue(this.ngControl.value));
    }
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
