import {Component, OnInit} from '@angular/core';
import {ReportsService} from '../../../reports/reports.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { LocationDateI } from 'src/app/Modals/location-DateI';
import { filter, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-power-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  reports = {};
  reportData = {
    title: null,
    reportId: null
  };

  reportListWithParameter = ['dailyCloseFuelDayReport',
                             'dailyFuelReceiptsReport',
                             'dailyFuelReclassificationReport',
                             'dailyFuelUpliftReport',
                             'dailyMeterSkipReport',
                             'dailyOwnerBalanceReport',
                             'dailyStockTransferReport',
                             'dailyUnitBalanceReport',
                             'dailyUnitVarianceReport',
                             'retailFuelSalesSummary',
                            ];

  reportTypeId;
  locationId: string;
  unsubscribeSubject = new Subject<boolean>();

  constructor(private reportService: ReportsService, private sanitizer: DomSanitizer,
              private loginService: LoginService) {
  }

  ngOnInit(): void {
    this.loginService.fuelLocationAndDate$
    .pipe(
      filter((locationAndDate: LocationDateI) => !!locationAndDate?.locationId),
      tap((locationAndDate: LocationDateI) => {
        this.locationId = locationAndDate?.locationId;
      }),
      takeUntil(this.unsubscribeSubject),
    ).subscribe(() => {
          Object.keys(environment.reports).forEach((reportUrl) => {
          this.reports[reportUrl] = this.sanitizer.bypassSecurityTrustResourceUrl(environment.reports[reportUrl] + (this.reportListWithParameter.findIndex((reportLink) => reportLink == reportUrl) >= 0 ? ('\''+ this.locationId +'\'') : ''));
        })
        this.reportService.reportId.subscribe(
          (data) => {
            this.reportTypeId = data;
          }
        )
      }
    );
  }

  filterReportType(reportType): any {
    if (reportType === 1) {
      this.reportData.title = 'CapacityMetrics';
      this.reportData.reportId = '762a13e5-e9d1-4e99-b303-6b9b9e7c91b5';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.complete();
  }
}
