import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ReportsService {

  reportId = new Subject<any>();

  constructor() {
  }

  getReportId(selectedReportId): any {
    this.reportId.next(selectedReportId);
  }

}
