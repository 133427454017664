import { Directive, OnInit, Renderer2, Input, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appResizeColumn]'
})

export class ResizeColumnDirective implements OnInit, OnDestroy {

  @Input('appResizeColumn') resizable = true;

  @Input() i: number;
  @Input() holderSelectors = 'resize-holder';
  @Input() resizingSelectors = 'resizing';
  @Input() matRowSelectors = 'mat-row';
  @Input() matCellSelectors = 'mat-cell';

  private startX: number;
  private startWidth: number;
  private grid: HTMLElement;
  private pressed: boolean;
  private readonly column: HTMLElement;

  constructor(private renderer: Renderer2,
              private el: ElementRef) {
    this.column = this.el.nativeElement;
  }

  ngOnInit(): void {
    if (this.resizable) {
      this.gridInit();
      this.renderInit();
    }
  }

  ngOnDestroy(): void {
    this.renderer.destroy();
  }

  private gridInit(): void {
    const row = this.renderer.parentNode(this.column);
    const thead = this.renderer.parentNode(row);
    this.grid = this.renderer.parentNode(thead);
  }

  private renderInit(): void {
    const resizer = this.renderer.createElement('span');
    this.renderer.addClass(
      resizer,
      this.holderSelectors
    );
    this.renderer.appendChild(
      this.column,
      resizer
    );
    this.renderer.listen(
      resizer,
      'mousedown',
      this.onMouseDown
    );
    this.renderer.listen(
      this.grid,
      'mousemove',
      this.onMouseMove
    );
    this.renderer.listen(
      'document',
      'mouseup',
      this.onMouseUp
    );
  }

  private onMouseDown = (event: MouseEvent) => {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
  }

  private onMouseMove = (event: MouseEvent) => {
    const offset: number = 35;
    if (this.pressed && event.buttons) {
      this.renderer.addClass(
        this.grid,
        this.resizingSelectors
      );
      let width: number = this.startWidth + (event.pageX - this.startX - offset);
      const gridCells: any[] = Array.from(this.grid.querySelectorAll(`.${this.matRowSelectors}`)).map(
        (row: any) => row.querySelectorAll(`.${this.matCellSelectors}`).item(this.i)
      );
      this.renderer.setStyle(
        this.column,
        'width',
        `${width}px`
      );
      for (const cell of gridCells) {
        this.renderer.setStyle(
          cell,
          'width',
          `${width}px`
        );
      }
    }
  }

  private onMouseUp = () => {
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeClass(
        this.grid,
        this.resizingSelectors
      );
    }
  }
}
