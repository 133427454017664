<div class="form-group">
    <label *ngIf="label" for="{{label}}" id="{{label}}">{{label}}</label>
    <div class="container-fluid p-0 m-0" style="position: relative;">
    <input type="text" 
        [id]="controlId"
        class="form-control" 
        [ngbTypeahead]="search" 
        [placeholder]="placeholder"
        [disabled]="control.disabled"
        [(ngModel)]="value"
        (click)="click$.next($any($event).target.value)"
        #instance="ngbTypeahead"
        [resultFormatter]="formatter" 
        [inputFormatter]="formatter"
        (blur)="onInputBlur()"
        (focus)="onFocus()"
        [ngClass]="(onFocusProp || touched) && (hasRequiredError || control.hasError('isValueNotSelected')) ? 'error-border' : 'no-error-border'"
        (selectItem)="onChange($event)"/>

        <!-- x icon when user types-in -->
        <i class="bi bi-x-circle pos-absolute mt-3-xicon _chevron cursor-pointer" 
            *ngIf="((value | icon) && !control.disabled) || control.hasError('isValueNotSelected')"
            (click)="reset(); click$.next('')">
        </i>

        <!-- down icon when no data entered in field -->
        <i class="bi bi-chevron-down pos-absolute mt-3-xicon _chevron cursor-pointer" 
            *ngIf="!control.hasError('isValueNotSelected') && !(value | icon)"
            (click)="click$.next('')">
        </i>
    </div>
<!-- required validation message -->
    <div class="fim-error"
                *ngIf="(onFocusProp || touched) && hasRequiredError">
                {{messagesConstants.requiredMessage}}
    </div>
    <!-- validation message if user doesnt select -->
    <div class="fim-error"
                *ngIf="(onFocusProp || touched) && control.hasError('isValueNotSelected')">
                Please select value from list
    </div>

    
</div>