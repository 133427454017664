<div class="modal-body alert-modal-outer-div">
    <div class="row alert-triangle-icon">
      <span class="c-font-c-5 text-center">
        <i class="bi bi-exclamation-triangle-fill" style="font-size: 60px;"></i>
      </span>
    </div>
    <div class="form-title c-font-20-b text-center">{{data.confirmHeader || 'Alert'}}</div>
    <div class="row alert-modal-content-text">
      <div class="col-12">
        {{data.dialogDescription}}
      </div>
    </div>


    <div class="my-2 text-center">
      <button *ngIf="data?.confirmButtonName" type="button" class="c-font-12 btn-common" (click)="onCancel()">{{data.confirmButtonName}}</button>
      <button *ngIf="cancelButtonName" type="button" class="btn-cancel c-font-c-5 c-font-12 ms-3" (click)="onConfirm()">{{data.cancelButtonName}}</button>
    </div>


    <button type="button" class="close display-none" aria-hidden="true" data-bs-dismiss="modal" #closeAddEditModal></button>
  </div>
