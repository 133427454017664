import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';

export interface BreadCrumb {
  label: string;
  url?: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  @Input() rows: BreadCrumb[] = [];
  @Input() title = '';
  subscription = new Subscription();
  path: BreadCrumbInterface[] = [];
  isLocationSelected$ = this.loginService.getLocationValidity$().pipe(distinctUntilChanged());


  constructor(private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.initCrumbs();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initCrumbs() {
    this.subscription.add(this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
      map(evt => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe((x: { breadCrumb: BreadCrumbInterface[] }) => {
        console.log(x);

        if (x.breadCrumb) {
          this.path = x.breadCrumb;
        }
      }));
  }

}

export interface BreadCrumbInterface {
  title: string;
  routerLink: string;
}
