import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { AppConstants } from '../app.constants';
import { StatusComponent } from '../shared/compponents/status/status.component';
@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  
  constructor(private _snackBar: MatSnackBar) {

  }

  /**
   * this will display message on top of the page
   * @param message message to display
   * @param type type of confirmation bar(success or error or warning....)
   * @param close to display x icon
   * @param duration how much time to display this message (optional)
   */
  showStatusMessage(message: string, type: string, close: boolean, duration?: number) {
    const config = {
      data: {
        message,
        type,
        close
      },
      duration: duration || AppConstants.timeout,
      horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
      verticalPosition: AppConstants.verticalposition as MatSnackBarVerticalPosition,
      panelClass: ['fim-snackbar']
    }

    this._snackBar.openFromComponent(StatusComponent ,config);

  }

}
