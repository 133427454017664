import { Component, Input, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'pop-over',
  templateUrl: './pop-over.component.html',
  styleUrls: ['./pop-over.component.css']
})
export class PopOverComponent implements OnInit {

  @Input() rowData: any;
  @Input() hoverData: any;
  @Input() displayCharLength?: number
  @Input() hoverInfo?: any;
  @Input() placement: string = 'right'; 
  
  appConstants = AppConstants;

  constructor() { }

  ngOnInit(): void {
  }

}
