import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/user/storage.service';
import { SpinnerService } from '../services/spinner.service';
import { AzureAdService } from '../shared/azure/azure-ad.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private storage: StorageService,
              private spinner: SpinnerService,
              private azure: AzureAdService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.storage.cleanAll();
    this.azure.logout();
  }

}
