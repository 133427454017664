import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorDescription'
})
export class ColorDescriptionPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {    

    switch (value) {

      case 'Green':
        return 'No validation issues with this particular Transaction'

      case 'Yellow':
        return 'Meter skip of 1 gallon'
        
      case 'Orange':
        return 'Duplicate ticket based on ASR or Document#'

      case 'Red1':
      return 'Meter roll of over 2 or more gallons from prior day (there should be no gap between days).'
    
      case 'Red2':
        return 'Meter roll of over 2 or more gallons from the same day.'  

      case 'Blue':
        return 'Unassigned fuel owner/ or incorrect setup of a fuel owner/invalid tail number (tail no specific to Fedex, delta Skywest, Skywest united).'
      
      case 'White':
        return 'Corrected transaction.' 
        
      default:
        return 'Unknown'
    }
  }

}
