import { NgModule } from '@angular/core';
import { HasNoRoleComponent } from './has-no-role.component';
import { HasNoRoleRoutingModule } from './has-no-role.routing.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    HasNoRoleComponent
  ],
  imports: [
    HasNoRoleRoutingModule,
    CommonModule
  ],
})

export class HasNoRoleModule {

}
