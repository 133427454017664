<div class="sidebar h-100"
     [ngClass]="{'animate-show-hide ng-hide': minibar, 'animate-show-hide ng-hide-remove': !minibar}">
  <ul class="nav flex-column sidebar">

    <!-- Home start-->
    <li class="nav-item side-menu-item">
      <div class="row">
        <div [ngClass]="{'col-6': minibar, 'col': !minibar}">
          <a class="nav-link c-font-c-2 no-hover-bg cursor-pointer cursor-pointer mt-1 mb-2" (click)="navigateTo('home')">
            <i class="bi bi-fuel-pump bic-2x m-1"></i>
            <label *ngIf="!minibar" class="c-font-18 ps-2 pt-1 c-font-c-7 side-menu-text"
                   [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">
              Signature FIM
            </label>
          </a>
        </div>
        <div class="text-right">
            <span class="menu-icon side-menu-text mr1">
              <i class="bi bi-x-circle bic-30  d-block d-sm-none _faClose"></i>
            </span>
        </div>
      </div>
    </li>

    <li class="nav-item sep-item">
      <hr>
    </li>
    <!-- Home end-->
    <ng-container *ngIf="showOptions">
    <!-- Correct Import Errors start-->
    <li id="correct_import_errors_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.CORRECT_IMPORT_GROUPS">
      <a class="nav-link ps-0" routerLink="importerrors" routerLinkActive="active">
        <i class="m-2 bi bi-exclamation-square-fill bic-30"></i>
        <span class="side-menu-text"
              (click)="expand('importerrors')"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">
          Correct Import Errors &nbsp;
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
          [ngClass]="correctImportErrorsCount > 0 ? 'bg-danger' : 'bg-success'">
            {{correctImportErrorsCount}}
          </span>
        </span>
      </a>
    </li>
    <!-- Correct Import Errors end-->

    <!-- Fuel Receiving start-->
    <li id="fuel_receiving_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.RECEIPT_ENTRY_GROUPS">
      <a class="nav-link ps-0" routerLink="receiving" routerLinkActive="active">
        <i class="m-2 bi bi-pen-fill bic-30"></i>
        <span id="sideNavFuelReceipt" class="side-menu-text"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Fuel Receiving</span>
      </a>
    </li>
    <!-- Fuel Receiving end-->

    <!-- Fuel Disbursement start-->
    <li id="fuel_disbursement_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.DISBURSEMENT_ENTRY_GROUPS">
      <a class="nav-link ps-0" routerLink="disbursement" routerLinkActive="active">
        <i class="m-2 bi bi-bezier bic-30"></i>
        <span class="side-menu-text"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Fuel Disbursement</span>
      </a>
    </li>
    <!-- Fuel Disbursement end-->

    <!-- Fuel Reconciliation start-->
    <li id="fuel_reconciliation_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.RECONCILIATION_GROUPS">
      <a class="nav-link ps-0" routerLinkActive="active">
        <i class="m-2 bi bi-bounding-box-circles bic-30"></i>
        <span
          class="side-menu-text sideBarBtn"
          (click)="expand('reconciliation')"
          [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Fuel Reconciliation<i *ngIf="!collapseReconciliation" class="bi bi-chevron-up bic-15 ml-3 mt-1 opacity-100"></i>
          <i *ngIf="collapseReconciliation" class="bi bi-chevron-down bic-15 ml-3 mt-1 opacity-100"></i>
        </span>
      </a>
    </li>

    <li class="collapsible" [@collapse]="collapseReconciliation">
      <ul class="ps-0" [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">

        <!-- Net Conversion Factor start-->
        <li id="net_conversion_factor_side_nav"  class="nav-item side-menu-item-sub collapsible" *ngIf="isNet">
          <a class="nav-link ps-0"
             routerLink="reconciliation/netconversion"
             *ngIf="!collapseReconciliation"
             [@collapse]="collapseReconciliation"
             routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Net Conversion Factor</span>
            </div>
          </a>
        </li>
        <!-- Net Conversion Factor end-->

        <!-- Stock Transfers start-->
        <li id="stock_transfers_side_nav" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
             routerLink="reconciliation/stocktransfers"
             *ngIf="!collapseReconciliation"
             [@collapse]="collapseReconciliation"
             routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Stock Transfers</span>
            </div>
          </a>
        </li>
        <!-- Stock Transfers end-->

        <!-- Physical Inventory start-->
        <li id="physical_inventory_side_nav" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
             routerLink="reconciliation/physicalinventory"
             *ngIf="!collapseReconciliation"
             [@collapse]="collapseReconciliation"
             routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Physical Inventory</span>
            </div>
          </a>
        </li>
        <!-- Physical Inventory end-->

      </ul>
    </li>
    <!-- Fuel Reconciliation end-->

    <!--Allocations start-->
    <li id="allocations_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.ALLOCATIONS_GROUPS">
      <a class="nav-link ps-0" routerLink="allocations" routerLinkActive="active">
        <i class="m-2 bi bi-arrow-up-right-square-fill bic-30"></i>
        <span class="side-menu-text"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Allocations</span>
      </a>
    </li>
    <!--Allocations end-->
    
    <!-- Fuel Closing - Start -->
    <li id="fuel_closing_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.RECONCILIATION_GROUPS">
      <a class="nav-link ps-0" routerLink="closing" routerLinkActive="active" (click)="onClosingOptionCLick()">
        <i class="m-2 bi bi-lock-fill bic-30"></i>
        <span class="side-menu-text"
              (click)="expand('closing')"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Fuel Closing</span>
      </a>
    </li>
    <!-- Fuel Closing - end-->

    <!-- Review For Billing start-->
    <li id="fuel_day_review_to_billing_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.RECONCILIATION_GROUPS">
      <a class="nav-link ps-0" routerLink="reviewforbilling" routerLinkActive="active">
        <i class="m-2 bi bi-file-earmark-check-fill bic-30"></i>
        <span class="side-menu-text" 
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Fuel Day Review to Billing</span>
      </a>
    </li>
    <!-- Review For Billing end-->

    <!-- Re-open Fuel Day start-->
    <li id="re_open_fuel_day_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.REOPEN_PREVIOUS_FUEL_DAY_GROUPS">
      <a class="nav-link ps-0" routerLink="reopen-fuelday" routerLinkActive="active">
        <i class="m-2 bi bi-unlock-fill bic-30"></i>
        <span class="side-menu-text"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Re-open Fuel Day</span>
      </a>
    </li>
    <!-- Re-open Fuel Day end-->

    <!-- Search Transaction - Start -->
    <li id="search_transaction_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.SEARCH_TRANSACTIONS_GROUPS">
      <a class="nav-link ps-0"
         routerLink="searchtransaction"
         routerLinkActive="active">
        <i class="m-2 bi bi-search bic-30"></i>
        <span class="side-menu-text"
              (click)="expand('searchtransaction')"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Search Transaction</span>
      </a>
    </li>
    <!-- Search Transaction - end-->

    <!-- Reports - Start -->
    <li id="reports_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.REPORT_SCREEN_GROUPS">
      <a class="nav-link ps-0"
         routerLink="reports"
         routerLinkActive="active">
        <i class="m-2 bi bi-file-earmark-post-fill bic-30"></i>
        <span class="side-menu-text"
              (click)="expand('searchtransaction')"
              [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Reports</span>
      </a>
    </li>
    <!-- Reports - end-->

    <!-- Admin start-->
    <li id="admin_side_nav" class="nav-item side-menu-item" appAuthGroups [groups]="groupsConstants.ADMIN_SCREEN_GROUPS">
      <a class="nav-link ps-0" routerLinkActive="active">
        <i class="m-2 bi bi-person-fill bic-30"></i>
        <span
          class="side-menu-text sideBarBtn"
          (click)="expand('admin')"
          [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">Admin<i *ngIf="!collapseAdmin" class="bi bi-chevron-up ml-3 mt-1 opacity-100 bic-15"></i>
          <i *ngIf="collapseAdmin" class="bi bi-chevron-down ml-3 mt-1 opacity-100 bic-15"></i>
        </span>
      </a>
    </li>

    <li id="admin_sub_items" class="collapsible" [@collapse]="collapseAdmin">
      <ul class="ps-0" [ngClass]="{'visible-content': minibar === false, 'hidden-content' : minibar === true }">

        <!-- Special Tag start-->
        <li id="special_tag_sub_item" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
              routerLink="admin/specialtag"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Special Tag</span>
            </div>
          </a>
        </li>
        <!-- Special Tag end-->

        <!-- Owner Fuel Type start-->
        <li id="owner_fuel_type_sub_item" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
              routerLink="admin/ownerfueltype"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Owner Fuel Type</span>
            </div>
          </a>
        </li>
        <!-- Owner Fuel Type end-->

        <!-- Unit start-->
        <li id="unit_sub_item" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
              routerLink="admin/unit"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Unit</span>
            </div>
          </a>
        </li>
        <!-- Unit end-->

        <!-- Supplier Fuel Type start-->
        <li class="nav-item side-menu-item-sub collapsible" appAuthGroups [groups]="groupsConstants.SUPPLIER_FUEL_TYPE_USERS">
          <a class="nav-link ps-0"
              routerLink="admin/supplierfueltype"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Supplier Fuel Type</span>
            </div>
          </a>
        </li>

        <!-- Customer Owner Mapping start-->
        <li id="customer_owner_mapping_sub_item" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
              routerLink="admin/customerownermapping"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Customer Owner Mapping</span>
            </div>
          </a>
        </li>
        <!-- Customer Owner Mapping end-->

        <!-- Customer location Mapping start-->
        <li id="customer_location_mapping_sub_item" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
              routerLink="admin/customerlocationmapping"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Customer Location Mapping</span>
            </div>
          </a>
        </li>
        <!-- Customer Owner Mapping end-->

        <!-- Customer Tail Number start-->
        <li id="customer_tail_number_sub_item" class="nav-item side-menu-item-sub collapsible" appAuthGroups [groups]="groupsConstants.CORPORATEADMIN_USER">
          <a class="nav-link ps-0"
              routerLink="admin/customertailnumber"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Customer Tail Number</span>
            </div>
          </a>
        </li>
        <!-- Customer Tail Number end-->
        
        <!-- Carrier Location Mapping start-->
        <li id="carrier_location_mapping_sub_item" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
              routerLink="admin/carrierlocationmapping"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Carrier Location Mapping</span>
            </div>
          </a>
        </li>
        <!-- Carrier Location Mapping end-->
      
        <!-- Agent Location Mapping start-->
        <li id="agent_location_mapping_sub_item" class="nav-item side-menu-item-sub collapsible">
          <a class="nav-link ps-0"
              routerLink="admin/agentlocationmapping"
              *ngIf="!collapseAdmin"
              [@collapse]="collapseAdmin"
              routerLinkActive="active">
            <div class="row">
              <span class="side-menu-text col-12 pt-3">
                <i class="bi bi-circle"></i>Agent Location Mapping</span>
            </div>
          </a>
        </li>
        <!-- Agent Location Mapping end-->
      
      </ul>
    </li>
    <!-- Admin end-->
  </ng-container>
  </ul>
</div>
