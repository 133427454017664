import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormControlName, FormGroup, FormGroupDirective } from '@angular/forms';
import { MaskDecimalService } from '../services/mask-decimal.service';
import { Subscription } from 'rxjs';
import { Broadcaster } from '../../services/broadcaster/broadcaster';

@Component({
  selector: 'app-decimal-field',
  templateUrl: './decimal-field.component.html',
  styleUrls: ['./decimal-field.component.css']
})
export class DecimalFieldComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id?;
  @Input() formControlName;
  @Input() isDisabled = false;
  @Input() maxValue = '0';
  @Output() changeEvent = new EventEmitter<any>();
  @Output() keyUpEvent = new EventEmitter<any>();
  @Input() isSmall = false;
  @Input() isNegative = false;
  @Input() value: string | number = '';

  tmpValue;
  form?: FormGroup;
  control?: FormControl;
  subscription = new Subscription();

  constructor(
    private formGroupDirective: FormGroupDirective,
    private maskService: MaskDecimalService,
    private broadcaster: Broadcaster,
    private formControlNameDirective: FormControlName
  ) {
  }

  ngOnInit(): void {
    if (this.formGroupDirective.form) {
      this.form = this.formGroupDirective.form;
      this.control = this.formGroupDirective.getControl(this.formControlNameDirective);

      if (this.control) {
        this.tmpValue = this.control.value;
        this.subscription.add(
          this.control.valueChanges.subscribe((data) => {
            this.tmpValue = data;
          })
        );
      }
    }
  }

  ngOnChanges(): void {
    if (this.value || this.value >= 0) {
      this.tmpValue = +this.value;
    }
  }

  @HostListener('focusout', ['$event'])
  public onFocusout(event): void {
    if (this.tmpValue && this.tmpValue != '-') {
      let decimal = this.maskService.getDecimal('' + this.tmpValue);
      decimal = decimal.length == 1 && decimal !== '0' ? decimal + '0' : decimal;
      const result = '' + `${this.maskService.getRounded('' + this.tmpValue, this.isNegative)}.${decimal || '00'}`;

      if (result && this.control) {
        this.control.setValue(
          result ? result : null
        );
      }
      if (this.control && this.isMaxValue(result) && this.maxValue !== '0') {
        this.control.setErrors({
          maxValue: true
        });
      }
      this.broadcaster.emit(Broadcaster.KEYS.formTouched, true);
      this.keyUpEvent.emit(this.tmpValue);
    } else {
      this.control.setValue('');
    }
    this.control.markAllAsTouched();
    this.changeEvent.emit(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onKeyUp(event): void {
    this.tmpValue = event.target.value;
    this.keyUpEvent.emit(event);
  }

  isMaxValue(value: string): boolean {
    if (this.maxValue) {
      const rounded = this.maskService.getRounded(this.maxValue, this.isNegative);
      const decimal = this.maskService.getDecimal(this.maxValue);
      const format = this.maskService.format(
        `${rounded}.${decimal || '00'}`,
        this.isNegative
      );
      if (+format < +value) {
        return true;
      }
    }
    return false;
  } 
}
