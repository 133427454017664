<div class="alert alert-dismissible fade show" [ngClass]="confirmationMessage.className">
    <div *ngIf="messageIsString; else arrayType">
        <span class="pad-h-20"><i class="mar-l-4" [ngClass]="confirmationMessage.iconName"></i></span>
        <span class="pad-h-20">{{data?.message}}</span>
    </div>
    <ng-template #arrayType>
        <ul *ngFor="let message of messages">
            <li>{{message}}</li>
        </ul>
    </ng-template>
    <button *ngIf="data?.close" type="button" (click)="closeSnackBar()" class="btn-close" data-bs-dismiss="alert"
        aria-label="Close"></button>
</div>