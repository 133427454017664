import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';

export interface GridActionsInterface {
  icon: string;
  text: string;
  subscribe: (element) => void;
}

export interface GridColumnsInterface {
  field: string;
  label: string;
  date?: 'MM/dd/yyyy';
  actions?: GridActionsInterface[];

  checkbox?: boolean;
  disabled?: boolean;

  each?: (value, el) => string;
  transform?: (val) => string;
  subscribe?: (el?, column?, event?, index?) => void;
}

@Component({
  selector: 'app-material-grid',
  templateUrl: './material-grid.component.html',
  styleUrls: ['./material-grid.component.scss'],
})
export class MaterialGridComponent implements OnInit, OnDestroy, OnChanges {

  @Input() rows: any[];
  @Input() columns: GridColumnsInterface[] = [];

  @Input() sortBy?: string;
  @Input() status = false;
  @Input() pagination = [10, 25, 50, 100];

  @Input() isSmall = false;
  @Input() isResize = false;
  @Input() isPagination = true;
  @Input() isMarginBottom = true;

  @Output() subscribe: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatPaginator, {
    static: true
  }) paginator: MatPaginator;
  @ViewChild(MatSort, {
    static: true
  }) sort: MatSort;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.source.sort) {
      this.source.sort = sort;
    }
  }

  index = -1;
  columnArr: string[] = [];
  source: MatTableDataSource<any>;
  subscription: Subscription = new Subscription();

  constructor() {
  }

  ngOnInit(): void {
    this.columns?.forEach((column: GridColumnsInterface) => {
      this.sortBy = this.sortBy || column.field;
      this.columnArr.push(column.field);
    });
  }

  ngOnChanges(): void {
    this.source = new MatTableDataSource<any>(this.rows);
    this.source.paginator = this.paginator;
    this.source.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onRecord<T>(data, i: number): void {
    if (this.subscribe) {
      this.index = i;
      this.subscribe.emit(data);
    }
  }

  onAction(event, element, btn): void {
    event.stopPropagation();
    btn.onClick(element);
  }

}
