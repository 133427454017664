export const breadCrumbs = {
    home: [],

    correctImports: [
        { title: 'Correct Import Errors' },
    ],

    fuelReceiving: {
        addReceipt: [
            { title: 'Fuel Receiving' }
        ],

        editReceipt: [
            { title: 'Fuel Receiving', routerLink: 'receiving/list' },
            { title: 'Edit' }
        ]
    },

    fuelDisbursement:
    {
        addDisbursement: [
            { title: 'Fuel Disbursement' }
        ],

        editDisbursement: [
            { title: 'Fuel Disbursement', routerLink: 'disbursement/list' },
            { title: 'Edit' }
        ]
    },

    fuelReconcilliation: {
        netConversionFactors: [{ title: 'Net Conversion Factor' }],
        stockTransfer: {
            overview: [
                { title: 'Stock Transfers', routerLink: '/reconciliation/stocktransfers' }
            ],
            edit: [
                { title: 'Edit' }
            ],
        },
        physicalInventory: {
            overview: [
                { title: 'Physical Inventory' }
            ],
            edit: [
                { title: 'Physical Inventory', routerLink: '/reconciliation/physicalinventory' },
                { title: 'Edit' }
            ]
        },
    },

    allocations: [
        { title: 'Allocations' }
    ],

    fuelClosing: [
        { title: 'Fuel Closing' }
    ],

    fuelDayReviewToBilling: [
        { title: 'Fuel Day Review to Billing' }
    ],

    reopenFuelDay: [
        { title: 'Re-open Fuel Day' }
    ],

    searchTransactions: [
        { title: 'Search Transactions' }
    ],

    reports: [
        { title: 'Reports' },
    ],

    admin: {

        specialTag: {
            overview: [
                { title: 'Special Tag' }
            ],
            edit: [
                { title: 'Special Tag', routerLink: 'admin/specialtag' },
                { title: 'Edit' }
            ]
        },

        ownerFuelType: {
            overview: [
                { title: 'Owner Fuel Type' }
            ],
            edit: [
                { title: 'Owner Fuel Type', routerLink: 'admin/specialtag' },
                { title: 'Edit' }
            ]
        },

        unit: {
            overview: [
                { title: 'Unit' }
            ],
            edit: [
                { title: 'Unit', routerLink: 'admin/unit' },
                { title: 'Edit' }]
        },

        supplierFuelType : {
            overview: [
                { title: 'Supplier Fuel Type' }
            ],
            edit: [
                { title: 'Supplier Fuel Type', routerLink: 'admin/supplierfueltype' },
                { title: 'Edit' }]
        },

        customerOwnerMapping: {
            overview: [
                { title: 'Customer Owner Mapping' }
            ],
            edit: [
                { title: 'Customer Owner Mapping', routerLink: 'admin/customerownermapping' },
                { title: 'Edit' }
            ]
        },

        customerLocationMapping: {
            overview: [
                { title: 'Customer Location Mapping' }
            ],
            edit: [
                { title: 'Customer Location Mapping', routerLink: 'admin/customerlocationmapping' },
                { title: 'Edit' }
            ]
        },

        customerTailNumber: {
            overview: [
                { title: 'Customer Tail Number' }
            ],
            edit: [
                { title: 'Customer Tail Number', routerLink: 'admin/customertailnumber' },
                { title: 'Edit' }
            ]
        },

        carrierLocationMapping: {
            overview: [
                { title: 'Carrier Location Mapping' }
            ],
            edit: [
                { title: 'Carrier Location Mapping', routerLink: 'admin/carrierlocationmapping' },
                { title: 'Edit' }
            ]
        },

        agentLocationMapping: {
            overview: [
                { title: 'Agent Location Mapping' }
            ],
            edit: [
                { title: 'Agent Location Mapping', routerLink: 'admin/agentlocationmapping' },
                { title: 'Edit' }
            ]
        }
    }
}