import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs';
import { LoaderService } from './services/loader-service';
import { AppConstants } from './app.constants';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      timeout(60000),
      map((event) => {
        if(this.checkIfUrlNeedsSpinner(request.url)) {
          event instanceof HttpResponse ? this.loaderService.hideLoader() : this.loaderService.showLoader();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hideLoader();
        if (error instanceof TimeoutError) {
          return throwError({
            "content": null,
            "error": {
              "code": 500,
              "messages": ['Timeout error'],
              "isError": true
            },
            "isSuccess": false
          })
        } else {
          return throwError(error);
        }
      })
    );
  }

  checkIfUrlNeedsSpinner(url): boolean {
    return !(AppConstants.urlsNotToShowSpinner.some((rUrl) => url.includes(rUrl)));
  }
}
