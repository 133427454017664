import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './services/user/storage.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from './services/loader-service';
import { FormChangesDeactivateGuardObject } from './unsaved.guard';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ModalPopupComponent } from './shared/compponents/modal-popup/modal-popup.component';
import { AppConstants } from './app.constants';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService,
              private loaderService: LoaderService,
              private router: Router,
              private dialog: MatDialog,
              private formChangesDeactivateGuardObject: FormChangesDeactivateGuardObject,
              @Inject('BASE_API_URL') private baseUrl: string) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.storageService.getAzureToken();
    const headers = request.headers
      .set('accept', '*/*')
      .set('Accept-Language', 'us-en')
      .set('Consumer', 'fim-ui')
      .set('TraceId', 'xyz')
      .set('Authorization', token ? `Bearer ${token}` : '');
    const req: HttpRequest<unknown> = request.clone({url: `${this.baseUrl}${request.url}`});
    return next.handle(req.clone({headers})).pipe(
      catchError(x => {
        return this.handleHttpError(x);
      })
    );
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.router.navigateByUrl('logout');
    } else if (err.status === 403) {
      this.loaderService.clearJobs();
      this.formChangesDeactivateGuardObject.formDirty = false;
      this.buildModalToDisplayCloseModal();
      return throwError(err.message);
    } else {
      return throwError(err);
    }
  }

  buildModalToDisplayCloseModal() {
    let dialogConfg = new MatDialogConfig();
    dialogConfg = {
      data: this.buildModalOptions(AppConstants.rolesNotAvailableMessage, AppConstants.HOME),
      disableClose: true
    };
    const dialogRef = this.dialog.open(ModalPopupComponent, dialogConfg);
    return dialogRef.afterClosed()
      .subscribe(
        () => this.navigateToLogout(),
        () => this.navigateToLogout()
      );
  }

  navigateToLogout(): void {
    if (this.router.url !== '/home') {
      this.router.navigateByUrl('/home');
    }
  }

  buildModalOptions = (dialogDescription: string, cancelButtonName: string) => {
    return {
      dialogTitle: 'Warning',
      additionaltext: '',
      dialogDescription,
      headerText: '',
      confirmHeader: '',
      cancelButtonName,
      height: '400px',
      width: '600px'
    };
  }
}
