import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HasNoRoleComponent } from './has-no-role.component';

const routes: Routes = [
  {
    path: 'has-no-role',
    component: HasNoRoleComponent,
    data: {
      layout: true,
      breadcrumb: [
        {
          title: 'Role required',
        }
      ]
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class HasNoRoleRoutingModule {

}

