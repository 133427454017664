import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { AppConstants } from '../../app.constants';

@Directive({
  selector: '[dateTimeFormat]',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: AppConstants.DATE_TIME_FORMAT
    },
  ],
})
export class DateTimePickerDirective {
}
