import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FormControlName, FormGroupDirective } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { AzureAdService } from '../azure/azure-ad.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AppConstants } from 'src/app/app.constants';

@Directive({
  selector: '[appRoleAccess]'
})
export class RoleAccessDirective implements OnInit, OnDestroy, OnChanges {

  @Input() roles: string[];
  @Input() notifyOnly: boolean;
  @Input() disableClassName = 'roleHasNoAccess';

  subscription = new Subscription();

  constructor(private el: ElementRef,
              private azure: AzureAdService,
              private notify: SnackbarService,
              private renderer: Renderer2,
              private formGroup: FormGroupDirective,
              private formControlName: FormControlName) {
  }

  ngOnInit(): void {
    this.subscription = fromEvent(
      this.el.nativeElement.parentNode,
      'click',
      {
        capture: true
      }
    ).subscribe((ev: any) => {
      if (ev.target.innerText === this.el.nativeElement.innerText) {
        if (
          this.notifyOnly &&
          !this.azure.isRoleAllow(this.roles) &&
          this.roles?.length
        ) {
          ev.stopImmediatePropagation();
          this.notify.showStatusMessage(environment.msg.actionDue, AppConstants.errorMessage, true);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(): void {
    this.handleInput();
    this.handleDom();
  }

  handleInput(): void {
    if (
      this.formControlName &&
      this.formGroup?.value &&
      !this.azure.isRoleAllow(this.roles) &&
      this.el?.nativeElement &&
      this.roles?.length
    ) {
      const control = this.formGroup.getControl(this.formControlName);
      if (control) {
        control.setErrors({
          roleHasNoAccess: true
        });
        control.disable();
        this.renderer.addClass(
          this.el.nativeElement,
          this.disableClassName,
        );
      }
    }
  }

  handleDom(): void {
    if (
      !this.notifyOnly &&
      !this.azure.isRoleAllow(this.roles) &&
      this.el?.nativeElement &&
      this.roles?.length
    ) {
      this.renderer.removeChild(
        this.el.nativeElement,
        this.el.nativeElement,
      );
    }
  }

}
