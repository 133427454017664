import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class StorageService {
  static KEYS = {
    azurePayload: `azurePayload_${environment.version}`,
    userGroups: `userGroups_${environment.version}`,
  };
  isSession: boolean;

  private getStorage(): Storage {
    return (this.isSession ? sessionStorage : localStorage);
  }

  getData(key: string): any | false {
    const data = this.getStorage().getItem(key);
    return data != null ? JSON.parse(data) : false;
  }

  setData(key: string, value: any): any {
    return this.getStorage().setItem(
      key,
      JSON.stringify(value)
    );
  }

  removeData(key: string): any {
    return this.getStorage().removeItem(key);
  }

  saveAzurePayload(payload: any): void {
    this.setData(StorageService.KEYS.azurePayload, payload);
  }

  getAzureToken(): string | undefined {
    const payload = this.getData(StorageService.KEYS.azurePayload);
    return payload ? payload.accessToken : undefined;
  }

  getAzurePayload(): any | undefined {
    return this.getData(StorageService.KEYS.azurePayload);
  }

  cleanAll(): void {
    const self: any = this;
    try {
      self.getStorage().clear();
    } catch (e) {
      console.log(e);
    }
  }

}
