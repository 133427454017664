import {NgModule} from '@angular/core';
import { BreadcrumbsComponent } from './compponents/breadcrumbs/breadcrumbs.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import { ModalPopupComponent } from './compponents/modal-popup/modal-popup.component';
import { HeaderComponent } from './compponents/header/header.component';
import { SidebarComponent } from './compponents/sidebar/sidebar.component';
import { CommonsModule } from '../commons.module';
import { MaterialModule } from '../material.module';
import { NgTypeaheadComponent } from './compponents/ng-typeahead/ng-typeahead.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaskDecimalDirective } from './directives/mask-decimal.directive';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { DateTimePickerDirective } from './directives/date-time-picker.directive';
import { DecimalFieldComponent } from './decimal-field/decimal-field.component';
import { MaskDecimalPipe } from './pipes/mask-decimal.pipe';
import { MaskDecimalService } from './services/mask-decimal.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusComponent } from './compponents/status/status.component';
import { ReportComponent } from './compponents/report/report.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { NoRecordsFoundComponent } from './compponents/no-records-found/no-records-found.component';
import { DisplayValuesMultiSelectionDropdownPipe } from './pipes/display-values-multi-selection-dropdown.pipe';
import { AzureAdModule } from './azure/azure-ad.module';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { FimSelectComponent } from './compponents/fim-select/fim-select.component';
import { FimTypeaheadComponent } from './compponents/fim-typeahead/fim-typeahead.component';
import { IconPipe } from './pipes/icon.pipe';
import { TimeSecDatePickerDirective } from './directives/time-sec-date.directive';
import { AuthGroupsDirective } from './authentication/auth-groups.directive';
import { RoleAccessDirective } from './directives/role-access.directive';
import { StringToNumberPipe } from './string-to-number.pipe';
import { ColorDescriptionPipe } from './pipes/color-description.pipe';
import { MaterialGridComponent } from './material-grid/material-grid.component';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { GlobalSearchComponent } from './compponents/global-search/global-search.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    ModalPopupComponent,
    NgTypeaheadComponent,
    MaskDecimalDirective,
    TimePickerComponent,
    DateTimePickerDirective,
    DecimalFieldComponent,
    MaskDecimalPipe,
    StatusComponent,
    ReportComponent,
    NoRecordsFoundComponent,
    DisplayValuesMultiSelectionDropdownPipe,
    FimSelectComponent,
    FimTypeaheadComponent,
    IconPipe,
    TimeSecDatePickerDirective,
    AuthGroupsDirective,
    RoleAccessDirective,
    StringToNumberPipe,
    ColorDescriptionPipe,
    MaterialGridComponent,
    ResizeColumnDirective,
    GlobalSearchComponent
  ],
  imports: [
    CommonModule,
    CommonsModule,
    RouterModule,
    MaterialModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    // AgGridModule,
    PowerBIEmbedModule,
    AzureAdModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    NgTypeaheadComponent,
    MaskDecimalDirective,
    TimePickerComponent,
    DateTimePickerDirective,
    DecimalFieldComponent,
    MaskDecimalPipe,
    ReportComponent,
    NoRecordsFoundComponent,
    DisplayValuesMultiSelectionDropdownPipe,
    FimSelectComponent,
    FimTypeaheadComponent,
    TimeSecDatePickerDirective,
    AuthGroupsDirective,
    RoleAccessDirective,
    StringToNumberPipe,
    ColorDescriptionPipe,
    MaterialGridComponent,
    ResizeColumnDirective,
    GlobalSearchComponent
  ],
  providers: [
    MaskDecimalService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { scrollStrategy: new NoopScrollStrategy() }
    }
  ]
})

export class SharedModule {

}
